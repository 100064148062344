import { useEffect, useState } from "react";
import { ProviderProps, VotedDelegation } from "../../../types";
import { addressStrWithDot } from "../../../lib";
import { UnknownProvider } from "../../../config/providers";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { ReactComponent as FileCopySvg } from "../../../assets/filecopy.svg";


interface props {
  provider: ProviderProps;
  item: VotedDelegation;
  rank: number
}

const DelegateeComponent = ({ provider, item, rank }: props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, copy] = useCopyToClipboard();

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      setIsOpen(false);
    }, 1000)
  }, [isOpen])

  return (
    <div className="py-1 flex border-b border-gray-600 mb-2 items-center">
      <div className="text-md w-[5%] text-left">
        {rank}
      </div>
      <div className="flex items-center tracking-tight text-gray-200 text-[14px] w-[45%]">
        <img
          src={provider?.logoURI ?? UnknownProvider.logoURI}
          className="w-6 mr-2"
          alt="logo"
        />
        <span>
          {
            provider?.name ?? UnknownProvider.name
          }
        </span>
      </div>
      <div className="text-sm w-[30%]">
        <span className="text-[12px] font-thin font-sans flex justify-start leading-[14px]">{addressStrWithDot(item.delegatee, 5, 4)}
          <FileCopySvg className="w-[14px] h-[14px] my-auto text-gray-300 fill-current" onClick={() => { copy(item.delegatee); setIsOpen(true) }} />
          {isOpen ? (<span className="mx-2 px-1 bg-gray-700 rounded-[4px]">copied!</span>) : null}
        </span>
      </div>
      <div className="text-sm text-left flex justify-between w-[20%]">
        Votes: <mark className="text-[#E9B83B] bg-transparent">{item.voted}</mark>
      </div>
    </div>
  )
}

export default DelegateeComponent;