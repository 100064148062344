import { useWeb3React } from "@web3-react/core";
import React, { useEffect } from "react";
import ScaleLoader from "../../../components/ScaleLoader";

export const WrapConfirmModal = ({
  handleCancelWrapConfirmModal,
  ableToWrap,
  amount,
  handleExchange,
}: any) => {
  const { chainId } = useWeb3React();
  const coin = (chainId === 19 ? "SGB" : (chainId === 14 ? "FLR" : "C2FLR"));
  const wcoin = (chainId === 19 ? "WSGB" : chainId === 14 ? "WFLR" : "C2WFLR");
  useEffect(() => {
    handleExchange();
  }, []);

  return (
    <div className="absolute container max-w-96 w-[90%] md:w-96 sm:w-[320px] sm:m-2 rounded-[14px] border border-gray-700 shadow-lg p-6 dark:bg-[#202231] bg-white h-auto">
      <div
        className="cursor-pointer absolute p-3 top-0 right-0 text-[24px] dark:text-gray-100 text-gray-600 transition duration-150 ease-in-out"
        onClick={handleCancelWrapConfirmModal}
      >
        &times;
      </div>
      <div className="relative p-6">
        <div className="flex justify-center py-6">
          <ScaleLoader color="#36d7b7" />
        </div>
      </div>
      <div className="text-center">
        <div className="py-2 text-lg">Waiting For Network Confirmation</div>
        <div className="bg-white dark:bg-[#202231] px-3 py-1 shadow-md my-2">
          {ableToWrap
            ? "Wrapping " + amount + coin
            : "Unwrapping " + amount + wcoin}
        </div>
        <div className="py-2 text-sm text-gray-400">
          Follow prompts from your wallet
        </div>
      </div>
    </div>
  );
};
