import { useEffect, useState } from "react";
import ArrowSvg from "../../assets/arrow.svg";
import {
  SGB as initialSgb,
  WSGB as initialWsgb,
  FLR as initialFlr,
  WFLR as initialWflr,
} from "../../config/providers";
import useConnect from "../../hooks/useConnect";
import { useBalances, useWrap } from "../../hooks/useContracts";
import { ChainId } from "../../types";
import { WrapModal } from "./components/WrapModal";
import { WrapConfirmModal } from "./components/WrapConfirmModal";
import { notifyInfo, notifyError } from "../../lib";

export const Wrap = () => {
  const { connect, active, chainId } = useConnect();
  const { wrap, unwrap, loading } = useWrap();
  const { nat, wNat, update } = useBalances();
  const [maxAmount, setMaxAmount] = useState(0);
  const [firstComponent, setFirstComponent] = useState(initialFlr);
  const [secondComponent, setSecondComponent] = useState(initialWflr);
  const [ableToWrap, setAbleToWrap] = useState(true);
  const [isOpenWrapModal, setIsOpenWrapModal] = useState(false);
  const [isOpenWrapConfirmModal, setIsOpenWrapConfirmModal] = useState(false);
  useEffect(() => {
    if (chainId === 14 || chainId === 114) {
      setAbleToWrap(true);
      setFirstComponent(initialFlr);
      setSecondComponent(initialWflr);
      let _temp_max = ableToWrap ? (nat > 1 ? nat - 1 : 0) : wNat;
      setMaxAmount(_temp_max);
    } else if (chainId === 19) {
      setAbleToWrap(true);
      setFirstComponent(initialSgb);
      setSecondComponent(initialWsgb);
      let _temp_max = ableToWrap ? (nat > 1 ? nat - 1 : 0) : wNat;
      setMaxAmount(_temp_max);
    }
  }, [chainId, wNat, nat]);
  useEffect(() => {
    let _temp_max = ableToWrap ? (nat > 1 ? nat - 1 : 0) : wNat;
    setMaxAmount(_temp_max);
    let _temp_first = structuredClone(firstComponent);
    _temp_first.status.amout = 0;
    setFirstComponent(_temp_first);
    let _temp_second = structuredClone(secondComponent);
    _temp_second.status.amount = 0;
    setSecondComponent(_temp_second);
  }, [ableToWrap]);
  const handleComponentExchange = () => {
    let temp = firstComponent;
    setFirstComponent(secondComponent);
    setSecondComponent(temp);
    setAbleToWrap(!ableToWrap);
  };
  const handleClickWrapBtn = (mode: string) => {
    let strAmount = firstComponent.status.amount;
    const amount = strAmount === "" ? 0 : parseFloat(strAmount);
    if (amount === 0 || isNaN(amount)) {
      notifyInfo("Input amount");
      return;
    }
    if (mode === "wrap") {
      setIsOpenWrapModal(true);
    } else if (mode === "unwrap") {
      setIsOpenWrapModal(true);
    }
    return;
  };
  const handleWrapConfirmModal = () => {
    setIsOpenWrapConfirmModal(true);
  };
  const handleCancelWrapConfirmModal = () => {
    setIsOpenWrapConfirmModal(false);
  };
  const handleCancelWrapModal = () => {
    setIsOpenWrapModal(false);
  };
  const handleExchange = () => {
    if (parseFloat(firstComponent.status.amount) <= 0) {
      return;
    }
    if (ableToWrap) {
      wrap(parseFloat(firstComponent.status.amount));
    } else {
      unwrap(parseFloat(firstComponent.status.amount));
    }
  };
  const handleChangeAmount = (e: any) => {
    if (e.target.validity.valid) {
      let amount = e.target.value === "" ? "" : e.target.value;
      if (ableToWrap && maxAmount < parseFloat(amount)) {
        if (nat > 1)
          notifyInfo(
            `Input ${coin} is too much. It cann't big than ` +
            (nat - 1).toFixed(2)
          );
        else notifyInfo(`Your ${coin} balance is not enough.`);

        return;
      }
      if (!ableToWrap && wNat < parseFloat(amount)) {
        notifyInfo(
          `Input ${wcoin} is too much. It cann't big than ` + wNat.toFixed(2)
        );
        return;
      }
      let _temp = structuredClone(firstComponent);
      let _temp1 = structuredClone(secondComponent);
      _temp.status.amount = amount;
      _temp1.status.amount = amount;
      setFirstComponent(_temp);
      setSecondComponent(_temp1);
    }
  };
  useEffect(() => {
    setIsOpenWrapConfirmModal(loading);
    setIsOpenWrapModal(loading);
    update();
  }, [loading]);
  const handleClickMaxbtn = () => {
    let _temp_first = structuredClone(firstComponent);
    _temp_first.status.amount = maxAmount.toFixed(2);
    setFirstComponent(_temp_first);
  };
  const coin = (chainId === 19 ? "SGB" : (chainId === 14 ? "FLR" : "C2FLR"));
  const wcoin = (chainId === 19 ? "WSGB" : chainId === 14 ? "WFLR" : "C2WFLR");
  return (
    <>
      <div className="relative flex justify-center">
        <div className="rounded-[24px] dark:bg-[#202231] shadow-dark-1000 shadow-md px-3 py-1 w-[90%] sm:w-[320px] md:w-96">
          <div className="p-2">
            <div className="tracking-tighter text-gray-600 cursor-pointer select-none ">
              {ableToWrap ? (
                <>
                  <span className="text-base leading-5 cursor-pointer dark:text-gray-200">
                    Wrap
                  </span>
                  {" / "}
                  <span
                    className="text-base leading-5 cursor-pointer"
                    onClick={handleComponentExchange}
                  >
                    Unwrap
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="text-base leading-5 cursor-pointer"
                    onClick={handleComponentExchange}
                  >
                    Wrap
                  </span>
                  {" / "}
                  <span className="text-base leading-5 cursor-pointer dark:text-gray-200">
                    Unwrap
                  </span>
                </>
              )}
            </div>
            <div className="relative pt-4 pb-2 tracking-tight">
              <div className="border rounded-[14px] border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]">
                <div className="flex w-full px-3 pt-3 pb-2 text-lg select-none">
                  <div className="flex w-2/5">
                    <div className="flex justify-center items-center gap-x-2 h-8 px-2 py-2 rounded-full shadow-md cursor-pointer dark:bg-[#202231] dark:hover:bg-[#2e3348] text-xl">
                      <img
                        className={firstComponent.display.picInfo.className}
                        src={firstComponent.display.picInfo.url}
                        alt=""
                      />
                      <span className="uppercase">
                        {firstComponent.display.picInfo.title}
                      </span>
                    </div>
                  </div>
                  <div className="w-3/5">
                    <input
                      tabIndex={0}
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="text"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0"
                      minLength={1}
                      maxLength={79}
                      spellCheck="false"
                      min="1"
                      className="right-0 flex-auto w-full py-0 pr-0 text-2xl text-right bg-transparent border-none h-7 hide-spinners display-none dark:text-gray-300 focus:outline-none"
                      value={firstComponent.status.amount}
                      onChange={handleChangeAmount}
                    />
                  </div>
                </div>
                <div className="flex justify-between px-4 pb-2 text-sm font-medium tracking-normal text-gray-400 dark:text-gray-500">
                  <span className="cursor-pointer">
                    Balance: {ableToWrap ? nat.toFixed(2) : wNat.toFixed(2)}
                  </span>
                  <span
                    className="cursor-pointer border rounded-[8px] py-[2px] px-[4px] border-gray-600 hover:border-gray-600"
                    onClick={handleClickMaxbtn}
                  >
                    Max: {maxAmount.toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="border rounded-[14px] border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622] mt-1">
                <div className="flex w-full px-3 pt-3 pb-2 text-lg select-none">
                  <div className="flex w-2/5">
                    <div className="flex justify-center items-center gap-x-2 h-8 py-2 px-2 rounded-full shadow-md cursor-pointer dark:bg-[#202231] dark:hover:bg-[#2e3348] text-xl">
                      <img
                        className={secondComponent.display.picInfo.className}
                        src={secondComponent.display.picInfo.url}
                        alt=""
                      />
                      <span className="uppercase">
                        {secondComponent.display.picInfo.title}
                      </span>
                    </div>
                  </div>
                  <div className="w-3/5">
                    <input
                      tabIndex={0}
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="text"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0"
                      minLength={1}
                      maxLength={79}
                      spellCheck="false"
                      min="1"
                      className="right-0 flex-auto w-full py-0 pr-0 text-2xl text-right bg-transparent border-none h-7 hide-spinners display-none focus:ring-0 dark:text-gray-300 focus:outline-none"
                      value={secondComponent.status.amount}
                      readOnly
                    />
                  </div>
                </div>
                <div className="pb-2 pl-4 text-sm font-medium tracking-normal text-gray-400 dark:text-gray-500">
                  <span className="cursor-pointer">
                    Balance: {ableToWrap ? wNat.toFixed(2) : nat.toFixed(2)}
                  </span>
                </div>
              </div>

              <div
                className="absolute rounded-full border border-gray-700 hover:border-gray-800 dark:border-gray-600 bg-white dark:bg-[#161622] h-7 w-7 -mt-2 left-1/2 top-1/2 flex justify-center items-center cursor-pointer"
                onClick={handleComponentExchange}
              >
                <img
                  className="text-high-emphesis current-color hover:text-white text-white dark:text-white w-[14px]"
                  src={ArrowSvg}
                  alt=""
                />
              </div>
            </div>
            {chainId ? (
              ableToWrap ? (
                nat > 0.001 ? (
                  <div className="py-2">
                    <div
                      className=" rounded-[14px] dark:bg-[#DAA520] text-center py-2   disabled:opacity-75 cursor-pointer "
                      onClick={() => handleClickWrapBtn("wrap")}
                    >
                      <span className="uppercase">WRAP</span>
                    </div>
                  </div>
                ) : (
                  <div className="py-2">
                    <div className=" rounded-[14px] dark:bg-[#DAA520] text-center py-2 ">
                      Insufficient
                      <span className="uppercase">{coin}</span>
                      Balance
                    </div>
                  </div>
                )
              ) : wNat > 0.001 ? (
                <div className="py-2">
                  <div
                    className=" rounded-[14px] dark:hover:border-gray-600 bg-white dark:bg-[#DAA520] text-center py-2 cursor-pointer "
                    onClick={() => handleClickWrapBtn("unwrap")}
                  >
                    <span className="uppercase">Unwrap</span>
                  </div>
                </div>
              ) : (
                <div className="py-2">
                  <div className=" rounded-[14px] dark:bg-[#DAA520] text-center py-2 ">
                    Insufficient
                    <span className="uppercase ">{ }</span>
                    Balance
                  </div>
                </div>
              )
            ) : (
              <div className="py-2">
                <div
                  className=" rounded-[14px] dark:bg-[#DAA520] text-center py-2"
                  onClick={() => {
                    if (!active) {
                      connect().catch((e) => {
                        if (e.name === "UnsupportedChainIdError") {
                          notifyError("Please connect to Songbird or Flare");
                        }
                      });
                    }
                  }}
                >
                  Connect Wallet
                </div>
              </div>
            )}
          </div>
        </div>
        {isOpenWrapModal ? (
          <WrapModal
            handleWrapConfirmModal={handleWrapConfirmModal}
            ableToWrap={ableToWrap}
            handleCancelWrapModal={handleCancelWrapModal}
            amount={firstComponent.status.amount}
          />
        ) : (
          <></>
        )}
        {isOpenWrapConfirmModal ? (
          <WrapConfirmModal
            handleCancelWrapConfirmModal={handleCancelWrapConfirmModal}
            ableToWrap={ableToWrap}
            amount={firstComponent.status.amount}
            handleExchange={handleExchange}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
