import React, { useEffect, useState } from "react";
import { addressStrWithDot } from "../../../lib";
import {
  useDelegatedPercentages,
  useProviders,
} from "../../../hooks/useContracts";
import { ProviderProps, ChainId } from "../../../types";

const ProviderConfigureModal = ({
  handleCancelDelegateConfigureModal,
  handleOpenDelegatingConfirmationModal,
  providerStatus,
  maxPercentage,
}: any) => {
  const providers: ProviderProps[] = useProviders();
  const { getPercentage, update: updateDelegate } = useDelegatedPercentages();
  const [delegatingAmountPercentage, setDelegatingAmount] = useState("100");
  const handleChangeDelegatingAmountPercentage = (e: any) => {
    if (!e.target.validity.valid) {
      return;
    }
    const amount = e.target.value === "" ? "" : e.target.value;
    const amountNum = parseFloat(amount);
    if (amount === "") {
      setDelegatingAmount(amount);
    }
    if (amountNum > parseFloat(maxPercentage)) {
      return;
    }
    if (
      parseFloat((amountNum * 100).toFixed(2)) >
      Math.floor(parseFloat((amountNum * 100).toFixed(2)))
    ) {
      return;
    }

    setDelegatingAmount(amount);
  };
  const handleUndelegate = () => {
    if (delegatingAmountPercentage === "") {
      return;
    }
    handleOpenDelegatingConfirmationModal(
      "undelegate",
      delegatingAmountPercentage
    );
  };
  const handleDelegationSave = () => {
    if (delegatingAmountPercentage === "") {
      //alert when input field is empty//
      return;
    }
    handleOpenDelegatingConfirmationModal("save", delegatingAmountPercentage);
  };
  useEffect(() => {
    setDelegatingAmount(maxPercentage);
  }, []);

  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "modal") handleCancelDelegateConfigureModal && handleCancelDelegateConfigureModal();
  };
  return (
    <div className='fixed top-0 min-w-full h-screen bg-black bg-opacity-[0.5]' id="modal" onClick={handleOnBackDropClick}>
      <div className="absolute top-[30%] right-[50%] translate-x-[50%] rounded-[14px] bg-[#202231] shadow-dark-1000 shadow-md p-3 w-[90%] sm:w-[320px] md:w-96 m-auto">
        <div className="relative">
          <p className="relative p-2 pb-6 text-lg text-gray-600 select-none dark:text-gray-100">
            Providers
          </p>
          <div
            className="absolute top-0 right-0 text-2xl text-gray-600 transition duration-150 cursor-pointer dark:text-gray-100"
            onClick={handleCancelDelegateConfigureModal}
          >
            &times;
          </div>
        </div>
        <div className="relative flex items-center w-full h-14">
          <div className="items-center w-full sm:flex">
            <div className="flex justify-between m-3">
              <img className="h-8 " src={providerStatus.logoURI} alt="" />
              <div className="pl-4 text-left">
                <div>{providerStatus.name}</div>
                <div className="text-xs text-gray-400">
                  {addressStrWithDot(providerStatus.address, 8, 6)}
                </div>
              </div>
            </div>
            <div className="flex justify-between my-4 ml-auto text-xs sm:text-sm md:text-lg sm:block sm:my-0">
              <div className="rounded-[14px] bg-[#202231] hover:bg-gray-800 px-3 sm:px-1 sm:m-2  md:px-3 py-1 border border-gray-700 dark:border-slate-600 select-none">
                <input
                  type="text"
                  className="w-12 pr-1 text-right bg-transparent focus:outline-none"
                  max={100}
                  min={0}
                  pattern="^[0-9]*[.,]?[0-9]*$"
                  placeholder="0.0"
                  onChange={handleChangeDelegatingAmountPercentage}
                  value={delegatingAmountPercentage}
                />
                %
              </div>
              <div className="pt-1 text-sm text-center text-gray-500">
                Max: {maxPercentage}%
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-4 gap-x-3">
          <button
            className="w-2/5 text-xs sm:text-lg rounded-[14px] text-center border border-gray-700 bg-[#202231] hover:bg-gray-800 text-white py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
            onClick={handleUndelegate}
          >
            Undelegate
          </button>
          <button
            className="w-2/5 text-xs sm:text-lg rounded-[14px] text-center border border-gray-700 bg-[#202231] hover:bg-gray-800 text-white py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
            onClick={handleDelegationSave}
          >
            Delegate
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProviderConfigureModal;
