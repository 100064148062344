import { useState, useEffect } from "react";
import { IMetricCard, IIMetricCard } from "../../../types";
import { addressStrWithDot } from "../../../lib";
import { FLRMonitorBaseUrl, SGBMonitorBaseUrl } from "../../../config/const";
import { ReactComponent as FileCopySvg } from "../../../assets/filecopy.svg";
import { ReactComponent as LinkOutSVG } from "../../../assets/linkout.svg";
import { ReactComponent as ChartSVG } from "../../../assets/chart.svg";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";


export const MetricCard = ({ props, handleOpenDelegateConfigureModal, chainId }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, copy] = useCopyToClipboard();
  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      setIsOpen(false);
    }, 1000)
  }, [isOpen])
  return (
    <div className="rounded-[14px] bg-[#202231] px-3  py-1 my-[10px]">
      <div className="flex p-2">
        <div className="mr-2">
          <img src={props.item?.logoURI} alt="provider logo" className="w-[25px] h-[25px]" />
        </div>
        <div className="text-left">
          <span className="text-[14px] text-[#E9B83B] flex justify-start leading-[14px]">
            {props.item?.name}
            <a href={props.item?.url} target="_blank"><LinkOutSVG className="w-[14px] h-[14px] my-auto" /></a>
          </span>
          <span className="text-[12px] font-thin font-sans flex justify-start leading-[14px]">{addressStrWithDot(props.item?.address, 14, 10)}
            <FileCopySvg className="w-[14px] h-[14px] my-auto text-gray-300 fill-current" onClick={() => { copy(props.item.address); setIsOpen(true) }} />
            {isOpen ? (<span className="mx-2 px-1 bg-gray-700 rounded-[4px]">copied!</span>) : null}
          </span>
        </div>
      </div>
      <div className="rounded-[14px] text-[14px] text-start py-1 px-2 border border-gray-700 hover:border-gray-600 bg-[#161622]">
        <div>
          <p className="text-[16px]">Reward Rate: <mark className="text-[#E9B83B] bg-transparent">{props.property?.rewardRate.toFixed(3)}</mark>
          </p>
        </div>
        {/* <div>
          <p>Accuracy: <mark className="text-[#E9B83B] bg-transparent">{props.property?.accuracy}%</mark></p>
        </div>
        <div>
          <p>Availability: <mark className="text-[#E9B83B] bg-transparent">{props.property?.availability}%</mark></p> </div> */}
        <div className="flex flex-col justify-center">
          <p className="flex">Vote Power: <mark className="text-[#E9B83B] bg-transparent">{props?.property?.votePowerPer.toLocaleString()}%</mark>
            {/* <span className="ml-[20px]">
              <p className="leading-[12px] text-[12px]">fff</p>
              <p className="leading-[12px] text-[12px]">fff</p>
            </span> */}
          </p>
        </div>
        <div>
          <p>Fee: <mark className="text-[#E9B83B] bg-transparent">{props.property?.fee}%</mark></p>
        </div>
      </div>
      <div className="flex justify-between p-2">
        <a href={(chainId == 19 ? SGBMonitorBaseUrl : FLRMonitorBaseUrl) + props.item.address.toLowerCase()} className="my-auto" target="_blank">
          <ChartSVG className="w-[16px] h-[16px] bg-white fill-red-500 cursor-pointer" />
        </a>
        <button className="rounded-[8px] bg-[#E9B83B] px-[6px] mx-[3px]" onClick={() => handleOpenDelegateConfigureModal(props.item)}>Delegate</button>
      </div>
    </div>
  )
}