import { useEffect, useState } from "react";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}

const Modal = ({ isOpen, children }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen])

  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "modal") setOpenModal(false);
  };

  if (!openModal) return (null);

  return <div className='fixed top-0 min-w-full h-screen bg-black bg-opacity-[0.5]' id="modal" onClick={handleOnBackDropClick}>
    <div className='absolute top-[30%] right-[50%] translate-x-[50%] rounded-[14px] bg-[#202231] shadow-dark-1000 shadow-md px-3 py-1  w-full max-w-md m-auto'>
      <div className="p-3 block">
        <div className='relative'>
          <div className='cursor-pointer text-3xl absolute top-0 right-0 dark:text-gray-100 text-gray-600 transition duration-150' onClick={() => setOpenModal(false)}>
            &times;
          </div>
        </div>
        {children}
      </div>
    </div>
  </div>
}

export default Modal;