import { useState, useEffect } from "react";

interface timeInfo {
  target:number;
  startTime:number;
  callBack:Function | undefined;
}

export const CountdownTimer = ({target, startTime, callBack}:timeInfo) => {

  const [remainTime, setRemainTime] = useState(0);
  const [duration, setDuration] = useState(1);

  useEffect(() => {
    let currentTime = Math.floor(new Date().getTime()/1000);
    if(target<1 || startTime<1) return;
    if (target <= currentTime) {
      console.log(target - startTime,currentTime - startTime, currentTime,  target - startTime - (currentTime - startTime) % (target - startTime))
      setRemainTime(target - startTime - (currentTime - startTime) % (target - startTime));
      setDuration(target - startTime);
    } else {
      setRemainTime(target - currentTime);
      setDuration(target - startTime);
    }
   
  }, [target, startTime])

  useEffect(() => {
    if (remainTime < 1) return;
    const interval = setInterval(() => { 
      setRemainTime(remainTime - 1);
      if(remainTime<2 && callBack) callBack()
    }, 1000)
    return () => clearInterval(interval);

  }, [remainTime])

  if(remainTime<1) return (<></>);

  return (
    <>
      <div className="md:flex justify-between text-gray-400 dark:text-gray-300">
        <div className="text-red-600 text-[14px]">
          <code>{Math.floor(remainTime / (3600 * 24))}d:</code>
          <code>{Math.floor(remainTime % (3600 * 24) / (3600) / 10)}</code>
          <code>{Math.floor(remainTime % (3600 * 24) / (3600)) % 10}h:</code>
          <code>{Math.floor((remainTime % 3600) / 60 / 10)}</code>
          <code>{Math.floor((remainTime % 3600) / 60) % 10}m:</code>
          <code>{Math.floor(remainTime % 60 / 10)}</code>
          <code>{remainTime % 60 % 10}s</code>
        </div>
      </div>
      <div className="w-full h-1 bg-gray-400 rounded">
        <div className="bg-[#E61144] h-1 rounded max-w-[100%]"
          style={{
            width: `${(
              ((duration - remainTime) * 100) / duration
            ).toFixed(0)}%`,
          }}>
        </div>
      </div>
    </>
  )

}