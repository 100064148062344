import { useEffect, useState } from "react";
interface props {
  isTurn: boolean,
  setTurnStatus: Function,
}

export default function ToggleBtn({ isTurn, setTurnStatus }: props) {
  const [enabled, setEnabled] = useState(false);
  
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div className="flex">
        <label className="inline-flex relative items-right cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isTurn}
            readOnly
          />
          <div
            onClick={() => {
              setTurnStatus(!isTurn);
            }}
            className="w-11 h-6 bg-transparent border border-gray-700 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#DAA520]"
          ></div>
          <span className="ml-2 text-sm font-medium w-[30px] m-auto">
            {isTurn ? "ON" : "OFF"}
          </span>
        </label>
      </div>
    </div>
  );
}