import { useCallback, useEffect, useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { Web3Provider, ExternalProvider } from "@ethersproject/providers";
import { formatEther, formatUnits, parseEther } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";
import { ethers } from 'ethers';
import { ProviderProps, IIMetricCard, defaultMetricCard } from "../types";
import { useProviders, useCurrentRewardEpoch, useRewardManager, useVPToken, useContractAddress } from "./useContracts";
import { Multicall, ContractCallResults, ContractCallContext, } from 'ethereum-multicall';
import IFtsoRewardManagerJSON from "../contracts/userInterface/IFtsoRewardManager.json";
import IVPTokenJSON from "../contracts/userInterface/IVPToken.json";
import { FlareRpc, SongbirdRpc, Coston2Rpc } from "../config/const";

export const useMetrics = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [metricInfoes, setMetricInfoes] = useState<Record<string, IIMetricCard>>({});
  const [totalReward, setTotalReward] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [rewardEpochVotePowerBlock, setRewardEpochVotePowerBlock] = useState(0);
  const [providersArr, setProviderArr] = useState<ProviderProps[]>([]);

  const providers = useProviders();
  const { rewardManager, getEpochReward, rewardManagerAddress } = useRewardManager();
  const { FtsoManager, epoch: currentRewardEpoch } = useCurrentRewardEpoch();
  const { totalVotePower, getVotePower, wNatAddress } = useVPToken();
  const MulticallAddress = useContractAddress("MultiContract")

  const provider = new ethers.providers.JsonRpcProvider(chainId === 14 ? FlareRpc : (chainId === 19 ? SongbirdRpc : Coston2Rpc));

  useEffect(() => {
    if (!FtsoManager || currentRewardEpoch < 1) return;
    FtsoManager.getRewardEpochVotePowerBlock(currentRewardEpoch).then(res => {
      setRewardEpochVotePowerBlock(res.toNumber())
    })
  }, [FtsoManager, currentRewardEpoch])

  useEffect(() => {
    if (!account || !rewardManager || currentRewardEpoch < 1) return;

    rewardManager.getEpochReward(currentRewardEpoch).then(res => {
      setTotalReward(parseFloat(formatEther(res._totalReward)))
    });

  }, [account, rewardManager, currentRewardEpoch])

  const fetchData = useCallback(async (chunckSize: number) => {
    if (!rewardManager ||
      !rewardManagerAddress ||
      !wNatAddress ||
      currentRewardEpoch < 1 ||
      !MulticallAddress ||
      !provider ||
      providers.length < 1 ||
      rewardEpochVotePowerBlock < 1
    ) {
      return;
    }

    let temp: Record<string, IIMetricCard> = {};
    let tempArr: ProviderProps[] = [];
    setIsLoading(true);

    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: provider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_reward',
        contractAddress: rewardManagerAddress,
        abi: IFtsoRewardManagerJSON.abi,
        calls: [...providers.map((item => { return { reference: 'res_reward', methodName: 'getStateOfRewards', methodParameters: [item.address, currentRewardEpoch] } }))]
      },
      {
        reference: 'res_fee',
        contractAddress: rewardManagerAddress,
        abi: IFtsoRewardManagerJSON.abi,
        calls: [...providers.map((item => { return { reference: 'res_fee', methodName: 'getDataProviderCurrentFeePercentage', methodParameters: [item.address] } }))]
      },
      {
        reference: 'VPofAt',
        contractAddress: wNatAddress,
        abi: IVPTokenJSON.abi,
        calls: [...providers.map((item => { return { reference: 'VPofAt', methodName: 'votePowerOfAt', methodParameters: [item.address, rewardEpochVotePowerBlock] } }))]
      },
      {
        reference: 'VPof',
        contractAddress: wNatAddress,
        abi: IVPTokenJSON.abi,
        calls: [...providers.map((item => { return { reference: 'VPof', methodName: 'votePowerOf', methodParameters: [item.address] } }))]
      }
    ]

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const res = results.results;


    providers.forEach((item, index) => {
      let res_reward = res.res_reward.callsReturnContext[index].returnValues;
      let res_fee = res.res_fee.callsReturnContext[index].returnValues;
      let res_vpofAt = res.VPofAt.callsReturnContext[index].returnValues;
      let res_vp = res.VPof.callsReturnContext[index].returnValues;

      if (res_reward[0].length < 1) return;
      let metric: IIMetricCard = { ...defaultMetricCard };
      metric.item = item;
      metric.property = {
        address: res_reward[0][0],
        rewardRate: (10000 / parseFloat(formatUnits(res_fee[0], 0)) - 1) * parseFloat(formatUnits(res_reward[1][0])) * 100 / (res_vpofAt.length > 0 ? parseFloat(formatUnits(res_vpofAt[0])) : 1),
        accuracy: 100,
        availability: 100,
        votePowerPer: res_vp.length > 0 ? parseFloat(formatUnits(res_vp[0])) * 100 / totalVotePower : 0,
        votePower: res_vp.length > 0 ? parseFloat(formatUnits(res_vp[0])) : 0,
        fee: parseFloat(formatUnits(res_fee[0], 0)) / 100,
      }
      temp[res_reward[0][0]] = metric;
      tempArr.push(item);
    })
    setIsLoading(false);
    setMetricInfoes(temp);
    setProviderArr(tempArr);

  }, [rewardManager, rewardManagerAddress, wNatAddress, currentRewardEpoch, MulticallAddress, provider, providers, rewardEpochVotePowerBlock])

  useEffect(() => {
    if (
      !account ||
      !rewardManager ||
      providers.length < 1 ||
      currentRewardEpoch < 1 ||
      totalVotePower <= 1 ||
      rewardEpochVotePowerBlock < 1
    ) return;
    let chunckSize = 15;
    fetchData(chunckSize)
  }, [providers, account, rewardManager, currentRewardEpoch, totalVotePower, providers, rewardEpochVotePowerBlock])

  return {
    totalReward,
    metricInfoes,
    providers,
    isLoading,
    providersArr
  }
}