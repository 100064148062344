import React, { useEffect } from "react";
import Loader from "../../../components/Loader";
import { useDelegate } from "../../../hooks/useContracts";
import { ProviderProps, ChainId } from "../../../types";
import ScaleLoader from "../../../components/ScaleLoader";

export const ProviderUndelegateConfirmation = ({
  handleCancelDelegatingConfirmationModal,
  providerStatus,
  handleUndelegate,
}: any) => {
  useEffect(() => {
    handleUndelegate(providerStatus.address);
  }, []);
  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "modal") handleCancelDelegatingConfirmationModal && handleCancelDelegatingConfirmationModal("undelegate");
  };
  return (
    <div className='fixed top-0 min-w-full h-screen bg-black bg-opacity-[0.5]' id="modal" onClick={handleOnBackDropClick}>
      <div className="absolute top-[30%] right-[50%] translate-x-[50%] rounded-[14px] bg-[#202231] shadow-dark-1000 shadow-md p-3 w-[90%] sm:w-[320px] md:w-96 m-auto">
        <div
          className="cursor-pointer absolute p-3 top-0 right-0 text-[24px] dark:text-gray-100 text-gray-600 transition duration-150 ease-in-out"
          onClick={() => handleCancelDelegatingConfirmationModal("undelegate")}
        >
          &times;
        </div>
        <div className="relative p-6">
          <div className="flex justify-center py-6">
            <ScaleLoader color="#36d7b7" />
          </div>
        </div>
        <div className="text-center">
          <div className="py-2 text-lg">Waiting For Network Confirmation</div>
          <div className="bg-white dark:bg-[#202231] px-3 py-1 shadow-md my-2">
            Undelegate from {providerStatus.name}
          </div>
          <div className="py-2 text-sm text-gray-400">
            Follow prompts from your wallet
          </div>
        </div>
      </div>
    </div>
  );
};
