import React from 'react';
import { ProviderProps } from '../../../types';

export const ProviderComponent = (props: ComponentType) => {
  return (
    <div className='sm:flex items-center sm:justify-between border-b border-gray-600 h-20 sm:h-16 sm:px-3 px-6 sm:py-0 py-3 hover:bg-gray-50 dark:hover:bg-slate-700dark:hover:border-gray-600 dark:bg-[#161622] cursor-pointer select-none' onClick={() => props.handleChooseProvider(props.providerStatus)}>
      <div className='flex items-center justify-between tracking-tight text-gray-200'>
        <img src={props.providerStatus.logoURI} className='sm:w-6 sm:mr-4 w-8 mr-6' alt="" />
        <span className=''>{props.providerStatus.name}</span>
      </div>
      <div className='text-md text-center  sm:text-sm text-gray-400'>{props.providerStatus.address?.substring(0, 7) +
        "..." +
        props.providerStatus.address?.substr(props.providerStatus.address.length - 5, 5)}
      </div>
    </div>
  )
}

interface ComponentType {
  providerStatus: ProviderProps,
  handleChooseProvider: any;
}