/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IFtsoRewardManager,
  IFtsoRewardManagerInterface,
} from "../IFtsoRewardManager";

const _abi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_rewardOwners",
        type: "address[]",
      },
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
    ],
    name: "autoClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_rewardOwner",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "_wrap",
        type: "bool",
      },
    ],
    name: "claim",
    outputs: [
      {
        internalType: "uint256",
        name: "_rewardAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_rewardOwner",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_rewardEpochs",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "_dataProviders",
        type: "address[]",
      },
      {
        internalType: "bool",
        name: "_wrap",
        type: "bool",
      },
    ],
    name: "claimFromDataProviders",
    outputs: [
      {
        internalType: "uint256",
        name: "_rewardAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_rewardEpochs",
        type: "uint256[]",
      },
    ],
    name: "claimReward",
    outputs: [
      {
        internalType: "uint256",
        name: "_rewardAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_rewardEpochs",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "_dataProviders",
        type: "address[]",
      },
    ],
    name: "claimRewardFromDataProviders",
    outputs: [
      {
        internalType: "uint256",
        name: "_rewardAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_dataProvider",
        type: "address",
      },
      {
        internalType: "address",
        name: "_claimer",
        type: "address",
      },
    ],
    name: "getClaimedReward",
    outputs: [
      {
        internalType: "bool",
        name: "_claimed",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentRewardEpoch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_dataProvider",
        type: "address",
      },
    ],
    name: "getDataProviderCurrentFeePercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "_feePercentageBIPS",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_dataProvider",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
    ],
    name: "getDataProviderFeePercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "_feePercentageBIPS",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_dataProvider",
        type: "address",
      },
    ],
    name: "getDataProviderPerformanceInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "_rewardAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_votePowerIgnoringRevocation",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
    ],
    name: "getEpochReward",
    outputs: [
      {
        internalType: "uint256",
        name: "_totalReward",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_claimedReward",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getEpochsWithClaimableRewards",
    outputs: [
      {
        internalType: "uint256",
        name: "_startEpochId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_endEpochId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_beneficiary",
        type: "address",
      },
    ],
    name: "getEpochsWithUnclaimedRewards",
    outputs: [
      {
        internalType: "uint256[]",
        name: "_epochIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getInitialRewardEpoch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getRewardEpochToExpireNext",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
    ],
    name: "getRewardEpochVotePowerBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_beneficiary",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
    ],
    name: "getStateOfRewards",
    outputs: [
      {
        internalType: "address[]",
        name: "_dataProviders",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_rewardAmounts",
        type: "uint256[]",
      },
      {
        internalType: "bool[]",
        name: "_claimed",
        type: "bool[]",
      },
      {
        internalType: "bool",
        name: "_claimable",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_beneficiary",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rewardEpoch",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "_dataProviders",
        type: "address[]",
      },
    ],
    name: "getStateOfRewardsFromDataProviders",
    outputs: [
      {
        internalType: "uint256[]",
        name: "_rewardAmounts",
        type: "uint256[]",
      },
      {
        internalType: "bool[]",
        name: "_claimed",
        type: "bool[]",
      },
      {
        internalType: "bool",
        name: "_claimable",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_rewardOwner",
        type: "address",
      },
    ],
    name: "nextClaimableRewardEpoch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IFtsoRewardManager__factory {
  static readonly abi = _abi;
  static createInterface(): IFtsoRewardManagerInterface {
    return new utils.Interface(_abi) as IFtsoRewardManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IFtsoRewardManager {
    return new Contract(address, _abi, signerOrProvider) as IFtsoRewardManager;
  }
}
