import React, { useEffect, useState } from 'react';
import { useProviders } from '../../../hooks/useContracts';
import { ProviderComponent } from './ProviderComponent';
import { ProviderProps } from '../../../types';
import { ethers } from "ethers";
import { UnknownProvider } from '../../../config/providers';

const ProvidersModal = ({ handleCancel, isOpen, setting }: any) => {
  const providers: ProviderProps[] = useProviders();
  const [searchTxt, setSearchTxt] = useState('');
  const handleSearch = (e: any) => {
    setSearchTxt(e.target.value);
  }
  const handleChooseProvider = async (status: ProviderProps) => {
    await setting(status.address);
    handleCancel();

  }
  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "modal") handleCancel && handleCancel();
  };
  return (
    <div className='fixed top-0 min-w-full h-screen bg-black bg-opacity-[0.5]' id="modal" onClick={handleOnBackDropClick}>
      <div className='absolute  top-[30%] right-[50%] translate-x-[50%] z-10 border rounded-[24px] dark:bg-[#202231] dark:border-gray-700 shadow-dark-1000 shadow-md  m-auto w-[90%] sm:w-[320px] md:w-full max-w-md max-h-[450px]'>
        <div className='p-6'>
          <div className='relative'>
            <p className='relative text-lg text-gray-600 dark:text-gray-100 select-none p-2 pb-6'>Providers</p>
            <div className='cursor-pointer text-2xl absolute top-0 right-0 dark:text-gray-100 text-gray-600 transition duration-150' onClick={handleCancel}>
              &times;
            </div>
          </div>
          <div className=''>
            <div className='rounded-[14px] text-center border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm px-3 py-1 mb-4'>
              <input type="text" name="Search" placeholder="Search name or address" className='h-8 w-full focus:outline-none bg-transparent text-gray-300' onChange={handleSearch} />
            </div>
          </div>
          <div className='scrollbox h-96 max-h-72 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-[#161522] overflow-y-scroll'>
            {
              searchTxt === '' ?
                (
                  providers.map((provider, index) =>
                    <ProviderComponent key={index} handleChooseProvider={handleChooseProvider} providerStatus={{ ...provider }} />
                  )
                ) : ethers.utils.isAddress(searchTxt) ? (
                  providers.find(provider => {
                    return provider.address.toLowerCase() == (searchTxt.toLowerCase());
                  }) ? providers.filter(provider => {
                    return provider.address.toLowerCase() == (searchTxt.toLowerCase());
                  }).map((provider, index) =>
                    <ProviderComponent key={index} handleChooseProvider={handleChooseProvider} providerStatus={{ ...provider }} />
                  ) : (
                    <ProviderComponent handleChooseProvider={handleChooseProvider} providerStatus={{ ...UnknownProvider, address: searchTxt }} />
                  )
                ) : (
                  providers.filter(provider => {
                    return provider.name.toLowerCase().includes(searchTxt.toLowerCase())
                      || provider.address.toLowerCase().includes(searchTxt.toLowerCase());
                  }).map((provider, index) =>
                    <ProviderComponent key={index} handleChooseProvider={handleChooseProvider} providerStatus={{ ...provider }} />
                  )
                )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProvidersModal;
