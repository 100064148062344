export interface providerComponentProps {
  picInfo: pic;
  isSvg?: boolean | false;
}
export interface ItemType {
  tab_name: string;
  link_url: string;
}

export interface NavbarProps {
  tabs: Array<ItemType>;
  tabId: Number;
  tabClick: (id: number) => void;
}
export interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}
export type status = {
  amount: string | "0.00";
  balance: string | "0.00";
  price: string | "1.00";
};
export type pic = {
  id?: string | " ";
  className?: string | " ";
  url: string;
  alt?: string | " ";
  color?: string | "";
  title?: string | "Unknown";
};
export type Network = "flare" | "songbird" | "coston2";

export interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

export enum ChainId {
  Flare = 14,
  Songbird = 19,
  Coston2 = 114,
}

export interface Delegation {
  address: string;
  percentage: number;
}
export interface IProviderRewardsProps {
  address: string;
  amount: number;
  claimed: boolean;
  epoch: number;
}

export enum CurBox {
  Main,
  WrapBox,
  UnWrapBox,
  DelegateBox,
  SwapBox,
}

export interface IIMetricCard {
  item: ProviderProps;
  property: IProviderProperty;
}

export const defaultMetricCard: IIMetricCard = {
  item: {
    chainId: 0,
    name: "",
    description: "",
    url: "",
    address: "",
    logoURI: "",
    listed: false,
  },
  property: {
    address: "",
    rewardRate: 0,
    accuracy: 100,
    availability: 100,
    votePowerPer: 0,
    votePower: 0,
    fee: 0,
  }
}

export const initTokenInfo:TokenInfo = {
  balance:0,
  name: "",
  claimedAmount: 0,
  remainPerNFT:0,
  claimableAmount:0,
  unlockTimestamp: 0,
}

export interface ProviderProps {
  chainId: Number;
  name: string;
  description: string;
  url: string;
  address: string;
  logoURI: string;
  listed: boolean;
}

export interface IProviderProperty {
  address: string;
  rewardRate: number;
  accuracy: number;
  availability: number;
  votePowerPer: number;
  votePower: number;
  fee: number;
}

export interface IRankInfo {
  _rank: string;
  _address: string;
  _amount: string;
}

export interface IMetricCard {
  providerName: string;
  providerLogo: string;
  address: string;
  rewardRate: number;
  accuracy: number;
  availability: number;
  votePower: number;
  fee: number;
}

export interface IClaimExecutor {
  executorAddr: string;
  fee: number;
}

export interface IClaimableFlareDropMonths {
  startMonth: number;
  endMonth: number;
}

export interface IClaimableFlareDropAmount {
  _month: number;
  _claimableAmount: number;
}

export interface IAmountOfDelegation {
  address: string,
  amount: number
}

export interface TokenInfo {
  balance:number;
  name: string;
  claimedAmount: number;
  remainPerNFT: number;
  claimableAmount:number;
  unlockTimestamp: number;
}

export interface VotedDelegation {
  delegatee:string;
  voted:number
}

export const ItemsPerPage = 10;