export const DropDates = [
  "March 17, 2023",
  "April 16, 2023",
  "May 16, 2023",
  "June 15, 2023",
  "July 15, 2023",
  "August 14, 2023",
  "September 13, 2023",
  "October 13, 2023",
  "November 12, 2023",
  "December 12, 2023",
  "January 11, 2024",
  "February 10, 2024",
  "March 11, 2024",
  "April 10, 2024",
  "May 10, 2024",
  "June 9, 2024",
  "July 9, 2024",
  "August 8, 2024",
  "September 7, 2024",
  "Octorber 7, 2024",
  "Nobember 6, 2024",
  "December 6, 2024",
  "January 5, 2025",
  "February 4, 2025",
  "March 6, 2025",
  "April 5, 2025",
  "May 5, 2025",
  "June 4, 2025",
  "July 4, 2025",
  "August 3, 2025",
  "September 2, 2025",
  "Octorber 2, 2025",
  "November 1, 2025",
  "December 31, 2025",
  "January 30, 2026",
]