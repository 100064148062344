import { toast } from 'react-toastify';
import {IProviderRewardsProps} from '../types';

export const addressStrWithDot = (address: string, startStr: number, endStr: number,) => {
  return address?.substring(0, startStr) +
    "..." +
    address?.substr(address.length - endStr, endStr);
}

export const convertIpfs2CommonURI = (ipfsNativeUri:string) => {
  return ipfsNativeUri.replace("ipfs://", "https://ipfs.io/ipfs/");
}
export const  groupBy = (list:IProviderRewardsProps[], keyGetter:any)=> {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export const notifyInfo = (msg: string) => {
  toast(msg, {
    type:'info',
    autoClose: 500,
    draggable: true,
    hideProgressBar: false,
    theme: 'dark',
    className: 'border border-[#243c5a]',
  });
}
export const notifyError = (msg: string) => {
  toast(msg, {
    type:'error',
    autoClose: 3000,
    draggable: true,
    hideProgressBar: false,
    theme: 'dark',
    className: 'border border-[#243c5a]',
  });
}
export const notifyWarn = (msg: string) => {
  toast(msg, {
    type:'warning',
    autoClose: 3000,
    draggable: true,
    hideProgressBar: false,
    theme: 'dark',
    className: 'border border-[#243c5a]',
  });
}
export const notifySuccess = (msg: string) => {
  toast(msg, {
    type:'success',
    autoClose: 3000,
    draggable: true,
    hideProgressBar: false,
    theme: 'dark',
    className: 'border border-[#E9B83B]',
  });
}