import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useCallback, useEffect } from "react";
import { ProviderRpcError } from "../types";

export const injected = new InjectedConnector({ supportedChainIds: [14, 19, 114] });

const songbirdNetworkParams = {
  chainId: "0x13",
  chainName: "Songbird",
  rpcUrls: ["https://songbird-api.flare.network/ext/C/rpc"],
  nativeCurrency: {
    name: "SGB",
    symbol: "SGB",
    decimals: 18,
  },
  blockExplorerUrls: ["https://songbird-explorer.flare.network"],
};

const flareNetworkParams = {
  chainId: "0xe",
  chainName: "Flare",
  rpcUrls: ["https://flare-api.flare.network/ext/C/rpc"],
  nativeCurrency: {
    name: "FLR",
    symbol: "FLR",
    decimals: 18,
  },
  blockExplorerUrls: ["https://flare-explorer.flare.network/"],
};
const coston2NetworkParams = {
  chainId: "0x72",
  chainName: "Coston2",
  rpcUrls: ["https://coston2-api.flare.network/ext/C/rpc"],
  nativeCurrency: {
    name: "C2FLR",
    symbol: "C2FLR",
    decimals: 18,
  },
  blockExplorerUrls: ["https://coston2-explorer.flare.network/"],
};

const useConnect = () => {
  const { activate, error, active, account, chainId } = useWeb3React();
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;
  const switchOrAddNetwork = useCallback(async (chainId: 14 | 19 | 114) => {
    const w = window as typeof window & { ethereum: any };
    try {
      await w.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      window.location.reload();
    } catch (error) {
      if (
        (error as ProviderRpcError).code === 4902 ||
        (error as ProviderRpcError).code === -32603
      ) {
        try {
          await w.ethereum.request({
            method: "wallet_addEthereumChain",
            params:
              chainId === 14 ? [flareNetworkParams] : (chainId === 19 ? [songbirdNetworkParams] : [coston2NetworkParams]),
          });
          window.location.reload();
        } catch (error) { }
      }
    }
  }, []);
  const connect = useCallback(async () => {
    await activate(injected, (error) => {
      throw error;
    });
  }, [activate]);
  const balance = useCallback(() => getBalance(String(account)), [activate]);
  const getBalance = async (account: any) => {
    const w = window as typeof window & { ethereum: any };
    try {
      await w.ethereum.request({
        method: "eth_getBalance",
        params: [String(account)],
      });
    } catch (error) { }
  };

  const switchToFlare = useCallback(async () => {
    await switchOrAddNetwork(14);
  }, [switchOrAddNetwork]);

  const switchToSongbird = useCallback(async () => {
    await switchOrAddNetwork(19);
  }, [switchOrAddNetwork]);

  const switchToCoston2 = useCallback(async () => {
    await switchOrAddNetwork(114);
  }, [switchOrAddNetwork])

  return {
    account,
    chainId,
    connect,
    isUnsupportedChainIdError,
    active,
    switchToFlare,
    switchToSongbird,
    switchToCoston2,
    balance,
  };
};

export default useConnect;
