/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ClaimSetupManager,
  ClaimSetupManagerInterface,
} from "../ClaimSetupManager";

const _abi = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_governance",
        internalType: "address",
      },
      {
        type: "address",
        name: "_addressUpdater",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_feeValueUpdateOffset",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_minFeeValueWei",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_maxFeeValueWei",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_registerExecutorFeeValueWei",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "event",
    name: "AllowedClaimRecipientsChanged",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: false,
      },
      {
        type: "address[]",
        name: "recipients",
        internalType: "address[]",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ClaimExecutorFeeValueChanged",
    inputs: [
      {
        type: "address",
        name: "executor",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "validFromRewardEpoch",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "feeValueWei",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ClaimExecutorsChanged",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: false,
      },
      {
        type: "address[]",
        name: "executors",
        internalType: "address[]",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DelegationAccountCreated",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: false,
      },
      {
        type: "address",
        name: "delegationAccount",
        internalType: "contract IDelegationAccount",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DelegationAccountUpdated",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: false,
      },
      {
        type: "address",
        name: "delegationAccount",
        internalType: "contract IDelegationAccount",
        indexed: false,
      },
      {
        type: "bool",
        name: "enabled",
        internalType: "bool",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ExecutorRegistered",
    inputs: [
      {
        type: "address",
        name: "executor",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ExecutorUnregistered",
    inputs: [
      {
        type: "address",
        name: "executor",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "validFromRewardEpoch",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GovernanceCallTimelocked",
    inputs: [
      {
        type: "bytes4",
        name: "selector",
        internalType: "bytes4",
        indexed: false,
      },
      {
        type: "uint256",
        name: "allowedAfterTimestamp",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "bytes",
        name: "encodedCall",
        internalType: "bytes",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GovernanceInitialised",
    inputs: [
      {
        type: "address",
        name: "initialGovernance",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GovernedProductionModeEntered",
    inputs: [
      {
        type: "address",
        name: "governanceSettings",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MaxFeeSet",
    inputs: [
      {
        type: "uint256",
        name: "maxFeeValueWei",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MinFeeSet",
    inputs: [
      {
        type: "uint256",
        name: "minFeeValueWei",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RegisterExecutorFeeSet",
    inputs: [
      {
        type: "uint256",
        name: "registerExecutorFeeValueWei",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetExecutorsExcessAmountRefunded",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "excessAmount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SetLibraryAddress",
    inputs: [
      {
        type: "address",
        name: "libraryAddress",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "TimelockedGovernanceCallCanceled",
    inputs: [
      {
        type: "bytes4",
        name: "selector",
        internalType: "bytes4",
        indexed: false,
      },
      {
        type: "uint256",
        name: "timestamp",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "TimelockedGovernanceCallExecuted",
    inputs: [
      {
        type: "bytes4",
        name: "selector",
        internalType: "bytes4",
        indexed: false,
      },
      {
        type: "uint256",
        name: "timestamp",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "accountToDelegationAccount",
    inputs: [
      {
        type: "address",
        name: "_owner",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "",
        internalType: "address[]",
      },
    ],
    name: "allowedClaimRecipients",
    inputs: [
      {
        type: "address",
        name: "_owner",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "batchDelegate",
    inputs: [
      {
        type: "address[]",
        name: "_delegatees",
        internalType: "address[]",
      },
      {
        type: "uint256[]",
        name: "_bips",
        internalType: "uint256[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "cancelGovernanceCall",
    inputs: [
      {
        type: "bytes4",
        name: "_selector",
        internalType: "bytes4",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [],
    name: "checkExecutorAndAllowedRecipient",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
      {
        type: "address",
        name: "_claimFor",
        internalType: "address",
      },
      {
        type: "address",
        name: "_recipient",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "",
        internalType: "address[]",
      },
    ],
    name: "claimExecutors",
    inputs: [
      {
        type: "address",
        name: "_owner",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "delegate",
    inputs: [
      {
        type: "address",
        name: "_to",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_bips",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "delegateGovernance",
    inputs: [
      {
        type: "address",
        name: "_to",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "disableDelegationAccount",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IDelegationAccount",
      },
    ],
    name: "enableDelegationAccount",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "executeGovernanceCall",
    inputs: [
      {
        type: "bytes4",
        name: "_selector",
        internalType: "bytes4",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "feeValueUpdateOffset",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IFtsoManager",
      },
    ],
    name: "ftsoManager",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "_addressUpdater",
        internalType: "address",
      },
    ],
    name: "getAddressUpdater",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "_recipients",
        internalType: "address[]",
      },
      {
        type: "uint256",
        name: "_executorFeeValue",
        internalType: "uint256",
      },
    ],
    name: "getAutoClaimAddressesAndExecutorFee",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
      {
        type: "address[]",
        name: "_owners",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "_delegationAccount",
        internalType: "contract IDelegationAccount",
      },
      {
        type: "bool",
        name: "_enabled",
        internalType: "bool",
      },
    ],
    name: "getDelegationAccountData",
    inputs: [
      {
        type: "address",
        name: "_owner",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getExecutorCurrentFeeValue",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getExecutorFeeValue",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_rewardEpoch",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "_registered",
        internalType: "bool",
      },
      {
        type: "uint256",
        name: "_currentFeeValue",
        internalType: "uint256",
      },
    ],
    name: "getExecutorInfo",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "_feeValue",
        internalType: "uint256[]",
      },
      {
        type: "uint256[]",
        name: "_validFromEpoch",
        internalType: "uint256[]",
      },
      {
        type: "bool[]",
        name: "_fixed",
        internalType: "bool[]",
      },
    ],
    name: "getExecutorScheduledFeeValueChanges",
    inputs: [
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "_registeredExecutors",
        internalType: "address[]",
      },
      {
        type: "uint256",
        name: "_totalLength",
        internalType: "uint256",
      },
    ],
    name: "getRegisteredExecutors",
    inputs: [
      {
        type: "uint256",
        name: "_start",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_end",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "governance",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IGovernanceSettings",
      },
    ],
    name: "governanceSettings",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IGovernanceVotePower",
      },
    ],
    name: "governanceVP",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "initialise",
    inputs: [
      {
        type: "address",
        name: "_initialGovernance",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "isClaimExecutor",
    inputs: [
      {
        type: "address",
        name: "_owner",
        internalType: "address",
      },
      {
        type: "address",
        name: "_executor",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "libraryAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "maxFeeValueWei",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "minFeeValueWei",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "productionMode",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "registerExecutor",
    inputs: [
      {
        type: "uint256",
        name: "_feeValue",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "registerExecutorFeeValueWei",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "revokeDelegationAt",
    inputs: [
      {
        type: "address",
        name: "_who",
        internalType: "address",
      },
      {
        type: "uint256",
        name: "_blockNumber",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setAllowedClaimRecipients",
    inputs: [
      {
        type: "address[]",
        name: "_recipients",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "setAutoClaiming",
    inputs: [
      {
        type: "address[]",
        name: "_executors",
        internalType: "address[]",
      },
      {
        type: "bool",
        name: "_enableDelegationAccount",
        internalType: "bool",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "setClaimExecutors",
    inputs: [
      {
        type: "address[]",
        name: "_executors",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setLibraryAddress",
    inputs: [
      {
        type: "address",
        name: "_libraryAddress",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setMaxFeeValueWei",
    inputs: [
      {
        type: "uint256",
        name: "_maxFeeValueWei",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setMinFeeValueWei",
    inputs: [
      {
        type: "uint256",
        name: "_minFeeValueWei",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setRegisterExecutorFeeValueWei",
    inputs: [
      {
        type: "uint256",
        name: "_registerExecutorFeeValueWei",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "switchToProductionMode",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "allowedAfterTimestamp",
        internalType: "uint256",
      },
      {
        type: "bytes",
        name: "encodedCall",
        internalType: "bytes",
      },
    ],
    name: "timelockedCalls",
    inputs: [
      {
        type: "bytes4",
        name: "",
        internalType: "bytes4",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferExternalToken",
    inputs: [
      {
        type: "address",
        name: "_token",
        internalType: "contract IERC20",
      },
      {
        type: "uint256",
        name: "_amount",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "undelegateAll",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "undelegateGovernance",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [
      {
        type: "uint256",
        name: "_validFromEpoch",
        internalType: "uint256",
      },
    ],
    name: "unregisterExecutor",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "updateContractAddresses",
    inputs: [
      {
        type: "bytes32[]",
        name: "_contractNameHashes",
        internalType: "bytes32[]",
      },
      {
        type: "address[]",
        name: "_contractAddresses",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "updateExecutorFeeValue",
    inputs: [
      {
        type: "uint256",
        name: "_feeValue",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract WNat",
      },
    ],
    name: "wNat",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdraw",
    inputs: [
      {
        type: "uint256",
        name: "_amount",
        internalType: "uint256",
      },
    ],
  },
];

export class ClaimSetupManager__factory {
  static readonly abi = _abi;
  static createInterface(): ClaimSetupManagerInterface {
    return new utils.Interface(_abi) as ClaimSetupManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ClaimSetupManager {
    return new Contract(address, _abi, signerOrProvider) as ClaimSetupManager;
  }
}
