import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

export const WrapModal = ({
  handleWrapConfirmModal,
  ableToWrap,
  handleCancelWrapModal,
  amount,
}: any) => {
  const { chainId } = useWeb3React();
  const coin = (chainId === 19 ? "SGB" : (chainId === 14? "FLR": "C2FLR"));
  const wcoin = (chainId === 19 ? "WSGB" : chainId === 14? "WFLR": "C2WFLR");
  return (
    <div className="absolute container max-w-96 md:w-96 sm:w-[320px] w-[90%] sm:max-w-full sm:m-2  max-w-md   rounded-[14px] border border-gray-700 shadow-lg p-6 dark:bg-[#202231] bg-white">
      <div className="capitalize">
        {ableToWrap ? `Wrap ${coin}` : `unwrap ${wcoin}`}
      </div>
      <div className="text-sm text-gray-600 dark:text-gray-300">
        {ableToWrap
          ? `You are about to wrap ${coin} into ${wcoin} at a rate of 1:1. This process is reversible.`
          : `You are about to unwrap ${wcoin} into ${coin} at a rate of 1:1. This process is reversible.`}
      </div>
      <div className="items-center justify-center pt-2 md:flex gap-x-4">
        <div className="rounded-[14px]  dark:bg-[#202231] px-3 py-1 border dark:border-gray-700 mt-2 w-full">
          <div className="flex">
            <div>{parseFloat(amount)}</div>
            <div className="ml-auto uppercase">{ableToWrap ? coin : wcoin}</div>
          </div>
        </div>
        <span className="mt-2">&rarr;</span>
        <div className="rounded-[14px]  dark:bg-[#202231] px-3 py-1 border dark:border-gray-700 mt-2 w-full">
          <div className="flex">
            <div>{parseFloat(amount)}</div>
            <div className="ml-auto uppercase">{ableToWrap ? wcoin : coin}</div>
          </div>
        </div>
      </div>
      <div
        className="rounded-[14px] border dark:border-gray-700 dark:hover:bg-[#202231] text-center dark:bg-[#202231]  py-2 capitalize cursor-pointer mt-4"
        onClick={handleWrapConfirmModal}
      >
        confirm
      </div>
      <div
        className="cursor-pointer absolute top-0 right-0 mt-2 mr-4 dark:text-gray-100 text-gray-600 text-[24px]"
        onClick={handleCancelWrapModal}
      >
        &times;
      </div>
    </div>
  );
};
