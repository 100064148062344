import { ethers } from "ethers";
import FLR_logo from "../assets/coin/FLR.png";
import BTC_logo from "../assets/coin/BTC_logo.png";
import Doge_logo from "../assets/coin/Doge_logo.png";
import WFLR_logo from "../assets/coin/WFLR_logo.png";
import XRP_logo from "../assets/coin/XRP_logo.png";
import ETH_logo from "../assets/coin/ETH_logo.png";

export const FlareRpc = "https://flare-api.flare.network/ext/C/rpc";
export const SongbirdRpc = "https://songbird-api.flare.network/ext/C/rpc";
export const Coston2Rpc = "https://coston2-api.flare.network/ext/C/rpc";
export const FLRMonitorBaseUrl = "https://flare-ftso-monitor.flare.network/price?currency=XRP&startTime=30m&providerAddress=";
export const SGBMonitorBaseUrl = "https://songbird-ftso-monitor.flare.network/price?currency=XRP&startTime=30m&providerAddress=";

export const Coston2Provider = new ethers.providers.JsonRpcProvider(Coston2Rpc);
export const SongbirdProvider = new ethers.providers.JsonRpcProvider(SongbirdRpc);
export const FlareProvider = new ethers.providers.JsonRpcProvider(FlareRpc);

export const C2_wNat = "0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273"; 
export const C2_VISTOKEN_ADD = "0xd97fA50d91f8311797f87685788377d029e20940"
export const C2_ARC_ADD = "0x9554F08CBCf354bBC282f000bC39254a7aa0F8ce"
export const C2_NFTRewardManager_ADD = "0xb6532CB9Ad52Cf883aBa0635038826563ae121D6"
export const C2_Vote4Delegation_ADD = "0x4c277e70E0419a1D257808a763004139047cE187"
export const C2_Vault_ADD = "0x50f339f7AAAf604aC0A5a038B896eFFA7eDb3905"
export const C2_VistionDist_ADD = "0x010E0C333E62671CbEB7d49F4387C487FA1EBb68";


export const SGB_WNat = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";
export const SGB_ORACLE = "0xCdB019C0990c033724DA55f5A04bE6fd6ec1809d";
export const SGB_ORACLERC20 = "0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff";
export const SGB_PROERC20 = "0xf810576A68C3731875BDe07404BE815b16fC0B4e";
export const SGB_Vault_ADD = "0x3a107120b7065d13404B71C04A3e0AB25DB83cE1";
export const SGB_Vote4Delegation_ADD = "0x034AAE35b5B0e0470bc528e584e26955FaCF2643";

export const ERC20Currencies = [
  {
    displayName:"FLR",
    currencySymbol:"FLR",
    token:undefined,
    image:FLR_logo
  },
  {
    displayName:"WFLR",
    currencySymbol:"WFLR",
    token:"0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273",
    image:WFLR_logo
  },
  // {
  //   displayName:"fBTC",
  //   currencySymbol:"BTC",
  //   token:"0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273",
  //   image:BTC_logo
  // },
  // {
  //   displayName:"fETH",
  //   currencySymbol:"ETH",
  //   token:"0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273",
  //   image:ETH_logo
  // },
  // {
  //   displayName:"fDOGE",
  //   currencySymbol:"DOGE",
  //   token:"0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273",
  //   image:Doge_logo
  // },
  // {
  //   displayName:"fXRP",
  //   currencySymbol:"XRP",
  //   token:"0xC67DCE33D7A8efA5FfEB961899C73fe01bCe9273",
  //   image:XRP_logo
  // },
]
