import songbird from "./songbird.json";
import flare from "./flare.json";
import coston2 from "./coston2.json";
import { ChainId } from "../types";

const getDeployMap = (data: any[]) =>
  data.reduce((map, item) => ({ ...map, [item.name]: item }), {});

const getter =
  (map: Record<string, any>) =>
    (name: string): string =>
      map[name].address;

export const deploys = (chainId: number) => {
  if (chainId === ChainId.Flare) return getter(getDeployMap(flare));
  else if (chainId === ChainId.Songbird) return getter(getDeployMap(songbird));
  else return getter(getDeployMap(coston2));
};

export default deploys;
