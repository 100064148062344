/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { FtsoManager, FtsoManagerInterface } from "../FtsoManager";

const _abi = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_governance",
        internalType: "address",
      },
      {
        type: "address",
        name: "_flareDaemon",
        internalType: "contract FlareDaemon",
      },
      {
        type: "address",
        name: "_priceSubmitter",
        internalType: "contract IIPriceSubmitter",
      },
      {
        type: "uint256",
        name: "_firstEpochStartTs",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_priceEpochDurationSeconds",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_revealEpochDurationSeconds",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_rewardEpochsStartTs",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_rewardEpochDurationSeconds",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_votePowerIntervalFraction",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "event",
    name: "CleanupBlockNumberManagerFailedForBlock",
    inputs: [
      {
        type: "uint256",
        name: "blockNumber",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "CleanupBlockNumberManagerUnset",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "ClosingExpiredRewardEpochFailed",
    inputs: [
      {
        type: "uint256",
        name: "_rewardEpoch",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ContractRevertError",
    inputs: [
      {
        type: "address",
        name: "theContract",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "atBlock",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "string",
        name: "theMessage",
        internalType: "string",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DistributingRewardsFailed",
    inputs: [
      {
        type: "address",
        name: "ftso",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "epochId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "FallbackMode",
    inputs: [
      {
        type: "bool",
        name: "fallbackMode",
        internalType: "bool",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "FinalizingPriceEpochFailed",
    inputs: [
      {
        type: "address",
        name: "ftso",
        internalType: "contract IIFtso",
        indexed: false,
      },
      {
        type: "uint256",
        name: "epochId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint8",
        name: "failingType",
        internalType: "enum IFtso.PriceFinalizationType",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "FtsoAdded",
    inputs: [
      {
        type: "address",
        name: "ftso",
        internalType: "contract IIFtso",
        indexed: false,
      },
      {
        type: "bool",
        name: "add",
        internalType: "bool",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "FtsoFallbackMode",
    inputs: [
      {
        type: "address",
        name: "ftso",
        internalType: "contract IIFtso",
        indexed: false,
      },
      {
        type: "bool",
        name: "fallbackMode",
        internalType: "bool",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GovernanceProposed",
    inputs: [
      {
        type: "address",
        name: "proposedGovernance",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GovernanceUpdated",
    inputs: [
      {
        type: "address",
        name: "oldGovernance",
        internalType: "address",
        indexed: false,
      },
      {
        type: "address",
        name: "newGoveranance",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitializingCurrentEpochStateForRevealFailed",
    inputs: [
      {
        type: "address",
        name: "ftso",
        internalType: "contract IIFtso",
        indexed: false,
      },
      {
        type: "uint256",
        name: "epochId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PriceEpochFinalized",
    inputs: [
      {
        type: "address",
        name: "chosenFtso",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "rewardEpochId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RewardEpochFinalized",
    inputs: [
      {
        type: "uint256",
        name: "votepowerBlock",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "startBlock",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "MAX_TRUSTED_ADDRESSES_LENGTH",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "activate",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "active",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "addFtso",
    inputs: [
      {
        type: "address",
        name: "_ftso",
        internalType: "contract IIFtso",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "addRevertError",
    inputs: [
      {
        type: "address",
        name: "revertedContract",
        internalType: "address",
      },
      {
        type: "string",
        name: "message",
        internalType: "string",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimGovernance",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract CleanupBlockNumberManager",
      },
    ],
    name: "cleanupBlockNumberManager",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "daemonize",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint192",
        name: "totalRevertedErrors",
        internalType: "uint192",
      },
      {
        type: "uint64",
        name: "lastErrorTypeIndex",
        internalType: "uint64",
      },
    ],
    name: "errorData",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract FlareDaemon",
      },
    ],
    name: "flareDaemon",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IIFtsoRegistry",
      },
    ],
    name: "ftsoRegistry",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "priceEpochId",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "priceEpochStartTimestamp",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "priceEpochEndTimestamp",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "priceEpochRevealEndTimestamp",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "currentTimestamp",
        internalType: "uint256",
      },
    ],
    name: "getCurrentPriceEpochData",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getCurrentRewardEpoch",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "bool",
        name: "_fallbackMode",
        internalType: "bool",
      },
      {
        type: "address[]",
        name: "_ftsos",
        internalType: "contract IIFtso[]",
      },
      {
        type: "bool[]",
        name: "_ftsoInFallbackMode",
        internalType: "bool[]",
      },
    ],
    name: "getFallbackMode",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address[]",
        name: "_ftsos",
        internalType: "contract IIFtso[]",
      },
    ],
    name: "getFtsos",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "_firstPriceEpochStartTs",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_priceEpochDurationSeconds",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_revealEpochDurationSeconds",
        internalType: "uint256",
      },
    ],
    name: "getPriceEpochConfiguration",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IPriceSubmitter",
      },
    ],
    name: "getPriceSubmitter",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getRewardEpochVotePowerBlock",
    inputs: [
      {
        type: "uint256",
        name: "_rewardEpoch",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "getVotePowerIntervalFraction",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "governance",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "initialise",
    inputs: [
      {
        type: "address",
        name: "_governance",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "lastRewardedFtsoAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IIPriceSubmitter",
      },
    ],
    name: "priceSubmitter",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "proposeGovernance",
    inputs: [
      {
        type: "address",
        name: "_governance",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "address",
      },
    ],
    name: "proposedGovernance",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "removeFtso",
    inputs: [
      {
        type: "address",
        name: "_ftso",
        internalType: "contract IIFtso",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "replaceFtso",
    inputs: [
      {
        type: "address",
        name: "_ftsoToRemove",
        internalType: "contract IIFtso",
      },
      {
        type: "address",
        name: "_ftsoToAdd",
        internalType: "contract IIFtso",
      },
      {
        type: "bool",
        name: "_copyCurrentPrice",
        internalType: "bool",
      },
      {
        type: "bool",
        name: "_copyAssetOrAssetFtsos",
        internalType: "bool",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "rewardEpochDurationSeconds",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "votepowerBlock",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "startBlock",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "startTimestamp",
        internalType: "uint256",
      },
    ],
    name: "rewardEpochs",
    inputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "",
        internalType: "uint256",
      },
    ],
    name: "rewardEpochsStartTs",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IIFtsoRewardManager",
      },
    ],
    name: "rewardManager",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setContractAddresses",
    inputs: [
      {
        type: "address",
        name: "_rewardManager",
        internalType: "contract IIFtsoRewardManager",
      },
      {
        type: "address",
        name: "_ftsoRegistry",
        internalType: "contract IIFtsoRegistry",
      },
      {
        type: "address",
        name: "_voterWhitelister",
        internalType: "contract IIVoterWhitelister",
      },
      {
        type: "address",
        name: "_supply",
        internalType: "contract IISupply",
      },
      {
        type: "address",
        name: "_cleanupBlockNumberManager",
        internalType: "contract CleanupBlockNumberManager",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setFallbackMode",
    inputs: [
      {
        type: "bool",
        name: "_fallbackMode",
        internalType: "bool",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setFtsoAsset",
    inputs: [
      {
        type: "address",
        name: "_ftso",
        internalType: "contract IIFtso",
      },
      {
        type: "address",
        name: "_asset",
        internalType: "contract IIVPToken",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setFtsoAssetFtsos",
    inputs: [
      {
        type: "address",
        name: "_ftso",
        internalType: "contract IIFtso",
      },
      {
        type: "address[]",
        name: "_assetFtsos",
        internalType: "contract IIFtso[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setFtsoFallbackMode",
    inputs: [
      {
        type: "address",
        name: "_ftso",
        internalType: "contract IIFtso",
      },
      {
        type: "bool",
        name: "_fallbackMode",
        internalType: "bool",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setGovernanceParameters",
    inputs: [
      {
        type: "uint256",
        name: "_maxVotePowerNatThresholdFraction",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_maxVotePowerAssetThresholdFraction",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_lowAssetUSDThreshold",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_highAssetUSDThreshold",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_highAssetTurnoutThresholdBIPS",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_lowNatTurnoutThresholdBIPS",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "_rewardExpiryOffsetSeconds",
        internalType: "uint256",
      },
      {
        type: "address[]",
        name: "_trustedAddresses",
        internalType: "address[]",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256",
        name: "maxVotePowerNatThresholdFraction",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "maxVotePowerAssetThresholdFraction",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "lowAssetUSDThreshold",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "highAssetUSDThreshold",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "highAssetTurnoutThresholdBIPS",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "lowNatTurnoutThresholdBIPS",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "rewardExpiryOffsetSeconds",
        internalType: "uint256",
      },
      {
        type: "bool",
        name: "changed",
        internalType: "bool",
      },
      {
        type: "bool",
        name: "initialized",
        internalType: "bool",
      },
    ],
    name: "settings",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "_lastErrorBlock",
        internalType: "uint256[]",
      },
      {
        type: "uint256[]",
        name: "_numErrors",
        internalType: "uint256[]",
      },
      {
        type: "string[]",
        name: "_errorString",
        internalType: "string[]",
      },
      {
        type: "address[]",
        name: "_erroringContract",
        internalType: "address[]",
      },
      {
        type: "uint256",
        name: "_totalRevertedErrors",
        internalType: "uint256",
      },
    ],
    name: "showLastRevertedError",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "uint256[]",
        name: "_lastErrorBlock",
        internalType: "uint256[]",
      },
      {
        type: "uint256[]",
        name: "_numErrors",
        internalType: "uint256[]",
      },
      {
        type: "string[]",
        name: "_errorString",
        internalType: "string[]",
      },
      {
        type: "address[]",
        name: "_erroringContract",
        internalType: "address[]",
      },
      {
        type: "uint256",
        name: "_totalRevertedErrors",
        internalType: "uint256",
      },
    ],
    name: "showRevertedErrors",
    inputs: [
      {
        type: "uint256",
        name: "startIndex",
        internalType: "uint256",
      },
      {
        type: "uint256",
        name: "numErrorTypesToShow",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IISupply",
      },
    ],
    name: "supply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [
      {
        type: "bool",
        name: "",
        internalType: "bool",
      },
    ],
    name: "switchToFallbackMode",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferGovernance",
    inputs: [
      {
        type: "address",
        name: "_governance",
        internalType: "address",
      },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      {
        type: "address",
        name: "",
        internalType: "contract IIVoterWhitelister",
      },
    ],
    name: "voterWhitelister",
    inputs: [],
  },
];

export class FtsoManager__factory {
  static readonly abi = _abi;
  static createInterface(): FtsoManagerInterface {
    return new utils.Interface(_abi) as FtsoManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): FtsoManager {
    return new Contract(address, _abi, signerOrProvider) as FtsoManager;
  }
}
