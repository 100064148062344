import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import SGB from "../assets/SGB.svg";
import WSGB from "../assets/WSGB.svg";
import FLR from "../assets/FLR.svg";
import WFLR from "../assets/WFLR.svg";
import { ChainId } from "@/types";
import useConnect from "../hooks/useConnect";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const chainOptions = {
  "14": { icon: FLR, name: "Flare" },
  "19": { icon: SGB, name: "Songbird" },
  "114": { icon: FLR, name: "Coston2" },
  none: { icon: "", name: "Unknown" },
};

export const SelectMenu = (props: { chainId: number | undefined }) => {
  const { switchToFlare, switchToSongbird, switchToCoston2, connect } = useConnect();
  let chain = chainOptions["none"];
  if (props.chainId) {
    chain = chainOptions[String(props.chainId) as "114" | "19" | "14"];
  }
  return (
    <Listbox value={chain}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="flex items-center px-3 py-1 border-2 border-gray-100 rounded-lg w-36 hover:border-gray-700 dark:border-gray-800 dark:hover:border-gray-700 gap-x-2">
              <span className="flex items-center">
                {chain.icon ? (
                  <img
                    src={chain.icon}
                    alt=""
                    className="flex-shrink-0 w-6 h-6 rounded-full"
                  />
                ) : null}
                <span className="block ml-3 truncate">{chain.name}</span>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-gray-900 border-2 border-gray-800 rounded-lg cursor-pointer hover:border-gray-700 ">
                {Object.keys(chainOptions).map((option, index) =>
                  index !== 3 ? (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? " bg-indigo-600" : "",
                          "text-white relative cursor-pointer select-none py-2 pl-3 text-sm"
                        )
                      }
                      value={chainOptions[option as "19" | "14" | "114" | "none"]}
                    >
                      {() => (
                        <>
                          <div
                            className="flex items-center"
                            onClick={async () => {
                              if (option === "19") await switchToSongbird();
                              else if (option === "14") await switchToFlare();
                              else if (option === "114") await switchToCoston2();
                              await connect();
                            }}
                          >
                            <img
                              src={
                                chainOptions[option as "19" | "14" | "114" | "none"]
                                  .icon
                              }
                              alt=""
                              className="flex-shrink-0 w-6 h-6 rounded-full"
                            />
                            <span className="block ml-3 truncate">
                              {
                                chainOptions[option as "19" | "14" | "114" | "none"]
                                  .name
                              }
                            </span>
                          </div>
                        </>
                      )}
                    </Listbox.Option>
                  ) : (
                    <></>
                  )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
