import { useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { routes } from '../../config/routes';

export const Layout = ({ children }: any) => {
    const [tabId, setTabId] = useState(0);

    const handleClick = (tabId: number) => {
        setTabId(tabId);
    }
    return (
        <>
            <Navbar tabs={routes} tabClick={handleClick} tabId={tabId} />
            <main className='mb-[150px] pt-24 md:pt-12'>
                {children}
            </main>
        </>
    )
}
