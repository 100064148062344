import { BigNumber } from "@ethersproject/bignumber";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther, parseEther } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { C2_ARC_ADD, C2_NFTRewardManager_ADD, C2_VISTOKEN_ADD, C2_Vault_ADD, C2_Vote4Delegation_ADD, FlareProvider, Coston2Provider, SongbirdProvider, SGB_Vault_ADD, SGB_ORACLERC20, SGB_PROERC20, SGB_ORACLE, SGB_Vote4Delegation_ADD, SGB_WNat, C2_VistionDist_ADD } from "../config/const";
import deploys from "../deploys";
import ARCJSON from "../contracts/userInterface/Token1.json";
import ERC20JSON from "../contracts/userInterface/ERC20.json";
import WNatJSON from "../contracts/userInterface/IVPToken.json";
import ARCVaultJSON from "../contracts/userInterface/Archetype_Vault.json";
import OracleVaultJSON from "../contracts/userInterface/Oracle_Vault.json";
import ARCNFTRMJSON from "../contracts/userInterface/RM1.json";
import CSMJSON from "../contracts/userInterface/ClaimSetupManager.json";
import FtsoManagerJSON from "../contracts/userInterface/FtsoManager.json";
import FtsoRewardManagerJSON from "../contracts/userInterface/IFtsoRewardManager.json";
import DistJSON from "../contracts/userInterface/DistributionToDelegators.json";
import OracleNFTABI from "../contracts/userInterface/OracleNFT.json";
import DistributeVisionABI from "../contracts/userInterface/DistributeVision.json"
import {
  ChainId,
  Delegation,
  IProviderRewardsProps,
  IRankInfo,
  IClaimExecutor,
  ItemsPerPage,
  IClaimableFlareDropMonths,
  IClaimableFlareDropAmount,
  ProviderProps,
  IAmountOfDelegation,
  TokenInfo,
  VotedDelegation,
  initTokenInfo
} from "../types";
import {
  IWNat__factory,
  IVPToken__factory,
  FtsoManager__factory,
  IFtsoRewardManager__factory,
  IFtso__factory,
  Token1__factory,
  RM1__factory,
  RM2__factory,
  OracleToken__factory,
  ProphetToken__factory,
  ClaimSetupManager__factory,
  DistributionToDelegators__factory,
  Vote4Delegation__factory,
  ERC20__factory,
  Archetype_Vault__factory,
  Oracle_Vault__factory,
  ORACLEVote4Delegation__factory,
  DistributeVision__factory
} from "../typechain-types";
import { toast } from "react-toastify";
import { notifyInfo, notifyError, notifySuccess, notifyWarn, convertIpfs2CommonURI } from "../lib";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";

// const provider = new ethers.providers.JsonRpcProvider(Coston2Rpc);

export const useProviders = () => {
  const { chainId } = useWeb3React<Web3Provider>();
  const [providers, setProviders] = useState<ProviderProps[]>([]);

  useEffect(() => {
    if (!chainId) return;
    getting();
  }, [chainId])

  const getting = useCallback(async () => {
    if (!chainId) return;
    fetch("https://api.github.com/repos/TowoLabs/ftso-signal-providers/contents/bifrost-wallet.providerlist.json")
      .then(async (res) => {
        let output = await res.json();
        let providers = JSON.parse(atob(output.content)).providers;
        let _temp = providers.filter((provider: ProviderProps, index: any) => {
          return provider.chainId === chainId && provider.listed === true;
        })
        setProviders(_temp);
      }).catch((e) => {
        setProviders([]);
        console.log(e);
      })
  }, [chainId])
  return providers;
};

export const useContractAddress = (contractName: string) => {
  const { chainId } = useWeb3React();
  const address = useMemo(() => {
    if (!chainId) return undefined;
    return deploys(chainId)(contractName);
  }, [chainId]);
  return address;
};

/**
 * Wraps and unwraps native tokens.
 * `disabled` is true if the network is wrong or user has not connected their wallet.
 * @param network Songbird, Flare or Coston2
 */
export const useWrap = () => {
  const { active, chainId } = useWeb3React<Web3Provider>();

  const disabled = !active;

  const wNat = useWNat();

  const [loading, setLoading] = useState(false);

  /**
   * @param wrapAmount  Amount of NAT (FLR or SGB) to be converted into wNat
   */
  const wrap = useCallback(
    async (wrapAmount: number) => {
      if (!wNat) {
        notifyError("Connect your wallet");
        return;
      }
      try {
        const wei = parseEther(String(wrapAmount));
        setLoading(true);
        const tx = await wNat.deposit({ value: wei });
        await tx.wait();
        notifySuccess("Your Request Successed");
      } catch (e) {
        notifyWarn("Your Request Rejected");
      } finally {
        setLoading(false);
      }
    },
    [wNat]
  );

  /**
   * @param amount Amount of wNAT to be burned and converted into NAT
   */
  const unwrap = useCallback(
    async (amount: number) => {
      if (!wNat) {
        notifyError("Connect your wallet");
        return;
      }
      try {
        setLoading(true);
        const tx = await wNat.withdraw(parseEther(String(amount)));
        await tx.wait();
        notifySuccess("Your Request Successed");
      } catch (e) {
        notifyWarn("Your Request Rejected");
      } finally {
        setLoading(false);
      }
    },
    [wNat]
  );

  return { wrap, unwrap, loading, disabled };
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns IWNat contract
 */
const useWNat = () => {
  const { library } = useWeb3React();

  const wNatAddress = useContractAddress("WNat");

  const wNat = useMemo(() => {
    if (!wNatAddress) return undefined;
    return IWNat__factory.connect(wNatAddress, library.getSigner());
  }, [library, wNatAddress]);

  return wNat;
};

const useERC20 = (tokenAddr: string | undefined) => {
  const { library } = useWeb3React();

  const ERC20Token = useMemo(() => {
    if (!tokenAddr || !library) return undefined;
    return ERC20__factory.connect(tokenAddr, library.getSigner());
  }, [library, tokenAddr]);

  return ERC20Token;
}

/**
 * Delegates and undelegates vote power.
 * `disabled` is true if the network is wrong or user has not connected their wallet.
 * @param network Songbird, Flare or Coston2
 */
export const useDelegate = () => {
  const { active } = useWeb3React<Web3Provider>();

  const disabled = !active;

  const { vpToken } = useVPToken();

  const [loading, setLoading] = useState(false);

  /**
   * @param to Address to delegate to
   * @param percentage Delegation as percentage of wNat held (0-100). To undelegate input zero
   */
  const delegate = useCallback(
    async (to: string, percentage: number) => {
      if (!vpToken) {
        notifyError("Connect your wallet");
        return;
      }
      if (percentage < 0 || percentage > 100) {
        notifyWarn("Invalid percentage");
        throw new Error("Invalid percentage");
      }
      try {
        setLoading(true);
        const tx = await vpToken.delegate(to, percentage * 100);
        await tx?.wait();
        notifySuccess("Your Request Successed");
      } catch (e: any) {
        notifyInfo("Your Request Rejected");
      } finally {
        setLoading(false);
      }
    },
    [vpToken]
  );

  const undelegateAll = useCallback(async () => {
    if (!vpToken) {
      notifyError("Connect your wallet");
      return;
    }
    try {
      setLoading(true);
      const tx = await vpToken.undelegateAll();
      await tx.wait();
      notifySuccess("Your Request Successed");
    } catch (e) {
      notifyWarn("Your Request Rejected");
    } finally {
      setLoading(false);
    }
  }, [vpToken]);
  const undelegate = useCallback(
    async (to: string) => {
      if (!vpToken) {
        notifyError("Connect your wallet");
        return;
      }
      try {
        setLoading(true);
        const tx = await vpToken.delegate(to, 0);
        await tx.wait();
        notifySuccess("Your Request Successed");
      } catch (e: any) {
        notifyInfo("Your Request Rejected");
      } finally {
        setLoading(false);
      }
    },
    [vpToken]
  );

  return {
    delegate,
    undelegateAll,
    undelegate,
    loading,
    disabled,
  };
};

/**
 * Claims rewards
 * `canClaim` is true if there are UnClaimedEpochs with unclaimed rewards.
 * `disabled` is true if canClaim is false or the network is wrong or user has not connected their wallet.
 * @param network Songbird, Flare or Coston2
 */

export const useClaim = () => {
  const { account } = useWeb3React<Web3Provider>();

  const { rewardManager } = useRewardManager();

  const [UnClaimedEpochs, setUnClaimedEpochs] = useState<number[] | undefined>(
    undefined
  );
  const [epochsArr, setEpochsArr] = useState<number[] | undefined>(undefined);

  const [canClaim, setCanClaim] = useState(false);
  const [claimable, setClaimable] = useState(false);

  const { epoch } = useCurrentRewardEpoch();

  const [pending, setPending] = useState(0);

  const [claimed, setClaimed] = useState<boolean[]>();
  const [unclaimed, setUnclaimed] = useState(0);
  const [dataProviderRewardsStatus, setDataProviderRewardsStatus] = useState<
    IProviderRewardsProps[]
  >([]);

  const [loading, setLoading] = useState(false);

  const getUnclaimedEpochs = async () => {
    if (!rewardManager || !account) return undefined;
    rewardManager
      .getEpochsWithUnclaimedRewards(account)
      .then((unclaimed) => {
        if (unclaimed.length > 0) {
          setUnClaimedEpochs(unclaimed.map((e) => e.toNumber()));
        } else {
          setUnClaimedEpochs([]);
        }
      })
      .catch((e) => {
        notifyWarn("Cannot access ftsoRewardManager contract");
      });
  };
  useEffect(() => {
    getUnclaimedEpochs();
  }, [rewardManager, account, epoch]);
  useEffect(() => {
    if (!UnClaimedEpochs || epoch < 1) return;
    setCanClaim(UnClaimedEpochs && UnClaimedEpochs.length > 0);
    let _temp = [];
    _temp = UnClaimedEpochs;
    _temp.push(epoch);
    _temp.sort((a, b) => b - a);
    setEpochsArr(_temp);
  }, [UnClaimedEpochs, epoch]);

  useEffect(() => {
    if (!rewardManager || !account) return undefined;
    rewardManager
      ?.getStateOfRewards(account, epoch)
      .then((rewards) => {
        setPending(
          rewards._rewardAmounts.reduce((a, b) => {
            return a + Number(formatEther(b));
          }, 0)
        );
      })
      .catch((e) => {
        notifyError("Check the internet connection");
      });
  }, [epoch, rewardManager, account]);

  const setProviderRewardsStatusFunc = (
    addresses: string[],
    rewardAmount: BigNumber[],
    claimed: boolean[],
    epoch: number[]
  ) => {
    const res = addresses.map((address, index) => {
      return {
        address: address,
        amount: Number(formatEther(rewardAmount[index])),
        claimed: claimed[index],
        epoch: epoch[index],
      };
    });
    return res;
  };
  useEffect(() => {
    if (!epochsArr || epochsArr.length === 0 || !account || !rewardManager) return;

    Promise.all(
      epochsArr.map((epo) =>
        rewardManager.getStateOfRewards(account, epo)
          .then((rewards) => {
            const _tempEpoch = new Array(rewards._dataProviders.length).fill(epo);
            const _temp = setProviderRewardsStatusFunc(
              rewards._dataProviders,
              rewards._rewardAmounts,
              rewards._claimed,
              _tempEpoch
            );
            return _temp;
          })
          .catch((e) => {
            notifyInfo("Cannot access FTSORewardManager contract");
            return [];
          })
      )
    )
      .then((rewardsArr) => {
        const providerRewardsStatus = rewardsArr.flat();

        const dataClone = providerRewardsStatus.sort((a, b) => b.epoch - a.epoch);
        let claimableAmount = 0;
        for (let i = 0; i < dataClone.length; i++) {
          if (dataClone[i].epoch === epoch) continue;
          claimableAmount += dataClone[i].amount;
        }
        setUnclaimed(claimableAmount);
        setClaimable(claimableAmount > 0);
        setDataProviderRewardsStatus(dataClone);
      })
      .catch((e) => {
        notifyInfo("Cannot access FTSORewardManager contract");
      });
  }, [epochsArr, account, rewardManager]);

  const disabled = !account || !UnClaimedEpochs || !rewardManager;

  const claim = useCallback(async (wrap: boolean) => {
    if (!account || !UnClaimedEpochs || !rewardManager) return;
    try {
      setLoading(true);
      let temp = [];
      temp = UnClaimedEpochs.slice(0, 5);
      const tx = await rewardManager.claimReward(account, temp);
      await tx.wait();
      notifySuccess("Your Request Successed");
    } catch (e) {
      notifyWarn("Your Request Rejected");
    } finally {
      setLoading(false);
      rewardManager
        .getEpochsWithUnclaimedRewards(account)
        .then((unclaimed) => {
          if (unclaimed.length > 0) {
            setUnClaimedEpochs(unclaimed.map((e) => e.toNumber()));
          } else {
            setUnClaimedEpochs([]);
          }
        })
        .catch((e) => {
          notifyError("Check the internet connection");
        });
    }
  }, [rewardManager, UnClaimedEpochs, account]);

  return {
    claim,
    canClaim,
    pending,
    unclaimed,
    loading,
    disabled,
    claimable,
    dataProviderRewardsStatus,
    claimed,
  };
};
/**
 * @param network Songbird, Flare or Coston2
 * @returns Balances (nat and wNat), update function, error
 */
export const useBalances = () => {
  const { library, account } = useWeb3React<Web3Provider>();

  const { vpToken } = useVPToken();

  const [nat, setNat] = useState(0);
  const [wNat, setWNat] = useState(0);

  const getNat = useCallback(() => {
    if (!library || !account) return;

    try {
      library
        .getSigner()
        .getBalance()
        .then((balance: any) => formatEther(balance))
        .then((nat: any) => setNat(Number(nat)))
        .catch((e: any) => {
          toast("Cannot read balance", {
            type: "info",
            autoClose: 300,
            draggable: true,
            hideProgressBar: false,
            theme: "dark",
            className: "border border-[#243c5a]",
          });
        });
    } catch (e) { }
  }, [library, account]);

  const getWNat = useCallback(() => {
    if (!account || !vpToken) return;
    try {
      vpToken.balanceOf(account)
        .then((balance) => formatEther(balance))
        .then((wNat) => setWNat(Number(wNat)))
        .catch((e) => {
          // notifyWarn("Cannot read wsgb balance");
        });
    } catch (e) { }
  }, [vpToken, account]);

  useEffect(() => {
    getNat();
  }, [library, account]);

  useEffect(() => {
    getWNat();
  }, [vpToken, account]);

  const update = () => {
    getNat();
    getWNat();
  };

  return { nat, wNat, update };
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns getPercentage function which returns how many percentages (0-100) user has delegated to given provider,
 * update function and error
 */
export const useDelegatedPercentages = () => {
  const { account } = useWeb3React();
  const { vpToken } = useVPToken();
  const [delegations, setDelegations] = useState<Delegation[] | undefined>(undefined);
  const [delegateMap, setDelegateMap] = useState<{ [address: string]: number }>({});

  /**
   * @returns How much user has delegated to providerAddress in percentages (0-100)
   */
  const getPercentage = (providerAddress: string): number => {
    return delegateMap[providerAddress.toLowerCase()] ?? 0;
  };

  const getDelegateMap = useCallback(async () => {
    if (!account || !vpToken) return;
    try {
      await vpToken.delegatesOf(account)
        .then(([addresses, bipsBN, countBN, modeBN]: any) => {
          setDelegations(
            addresses.map((address: any, i: any) => ({
              address,
              percentage: bipsBN[i].toNumber() / 10000,
            }))
          );
          const bips = bipsBN.map((x: any) => x.toNumber());
          const entries = bips.map((bip: any, i: any) => [
            addresses[i].toLowerCase(),
            bip / 100,
          ]);
          setDelegateMap(Object.fromEntries(entries));
          let del = addresses.map((address: any, i: any) => ({
            address,
            percentage: bipsBN[i].toNumber() / 10000,
          }));
          let _temp = structuredClone(delegations);
          _temp = del;
          setDelegations(_temp);
        })
        .catch((e: any) => {
          notifyWarn("Cannot read the balance");
        });
    } catch (e) { }
  }, [vpToken, account]);

  useEffect(() => {
    getDelegateMap();
  }, [vpToken, account]);

  const update = () => {
    getDelegateMap();
  };

  return { delegations, getPercentage, update };
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns Percentage (0-100) of undelegated voting power.
 */
// export const useUndelegatedPercentage = () => {
//   const { account } = useWeb3React();
//   const { vpToken } = useVPToken();

//   const [undelegated, setUndelegated] = useState(BigNumber.from(0));
//   const [total, setTotal] = useState(BigNumber.from(0));

//   const getUndelegatedPercentage = useCallback(async () => {
//     if (!account || !vpToken) return;

//     const getUndelegated = async () => {
//       try {
//         const a = await vpToken.undelegatedVotePowerOf(account);
//         setUndelegated(a);
//       } catch (e) { }
//     };

//     const getTotal = async () => {
//       try {
//         const t = await vpToken.balanceOf(account);
//         setTotal(t);
//       } catch (e) { }
//     };

//     await Promise.all([getUndelegated(), getTotal()]);
//   }, [vpToken, account]);

//   useEffect(() => {
//     getUndelegatedPercentage();
//   }, [vpToken, account]);

//   const update = getUndelegatedPercentage;

//   if (total.toBigInt() === BigInt(0)) return { available: 0, update };
//   const available = undelegated.mul(1000).div(total).toNumber() / 10;
//   return { available, update };
// };

/**
 * @param network Songbird, Flare or Coston2
 * @returns IVPToken contract
 */
export const useVPToken = () => {
  const { library, account } = useWeb3React();
  const [totalVotePower, setTotalVotePower] = useState<number>(1);
  const wNatAddress = useContractAddress("WNat");

  const vpToken = useMemo(() => {
    if (!wNatAddress || !library) return undefined;
    return IVPToken__factory.connect(wNatAddress, library.getSigner());
  }, [library, wNatAddress]);

  useEffect(() => {
    if (!account || !vpToken) return;
    vpToken.totalVotePower().then(res => {
      setTotalVotePower(Number(formatEther(res)))
    })
  }, [account, vpToken]);

  const getVotePower = useCallback(async (owner: string) => {
    if (!account || !vpToken) return;
    return vpToken.votePowerOf(owner)
  }, [account, vpToken])
  return {
    vpToken,
    totalVotePower,
    getVotePower,
    wNatAddress,
  };
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns FtsoRewardManager contract
 */
export const useRewardManager = () => {
  const { library, chainId, account } = useWeb3React<Web3Provider>();
  const [totalReward, setTotalReward] = useState<number>(1);

  const rewardManagerAddress = useContractAddress("FtsoRewardManager");

  const rewardManager = useMemo(() => {
    if (!rewardManagerAddress || !library) return undefined;
    return IFtsoRewardManager__factory.connect(
      rewardManagerAddress,
      library.getSigner()
    );
  }, [library, rewardManagerAddress]);
  const getEpochReward = useCallback(async (epochId: number) => {
    if (!account || !rewardManager || epochId < 1) return 1;

    const res = await rewardManager.getEpochReward(epochId);
    return parseFloat(formatEther(res._totalReward));
  }, [account, rewardManager])

  return {
    rewardManager,
    getEpochReward,
    rewardManagerAddress
  };
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns Ftso contract
 */
const useFtsoNat = () => {
  const { library } = useWeb3React<Web3Provider>();

  const ftsoAddress = useContractAddress("FtsoNat");
  const ftso = useMemo(() => {
    if (!library || !ftsoAddress) return undefined;
    return IFtso__factory.connect(ftsoAddress, library.getSigner());
  }, [library, ftsoAddress]);
  return ftso;
};

/**
 * @param network Songbird, Flare or Coston2
 * @returns current price of Songbird, Flare or Coston2
 */
export const useNatPrice = () => {
  const ftso = useFtsoNat();
  const [price, setPrice] = useState<number | undefined>(undefined);
  const getPrice = async () => {
    if (!ftso) return undefined;
    try {
      const r = await ftso.getCurrentPrice();
      setPrice(r._price.toNumber());
    } catch {
      notifyError("Cannot read native token price");
      return undefined;
    }
    return price;
  };
  useEffect(() => {
    getPrice();
  }, [ftso]);
  return { price };
};

/**
 * useCurrentRewardEpoch()
 * @param network Songbird, Flare or Coston2
 * @returns CurEpochId, startTime, endTime
 */

export const useCurrentRewardEpoch = () => {
  const [epoch, setEpoch] = useState(0);
  const [currentPriceEpoch, setCurrentPriceEpoch] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [activeAmount, setActiveAmount] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [remainPriceEpochTime, setRemainPriceEpochTime] = useState(0);
  const [vpBlock, setVPBlock] = useState(0);

  const [priceEpochStartTimeStamp, setPriceEpochStartTimeStamp] = useState(0);
  const [priceEpochEndTimeStamp, setPriceEpochEndTimeStamp] = useState(0);

  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const { vpToken } = useVPToken();
  const ftsoManagerAddress = useContractAddress("FtsoManager");
  const MulticallAddress = useContractAddress("MultiContract");


  const FtsoManager = useMemo(() => {
    if (!ftsoManagerAddress || !library) return undefined;
    return FtsoManager__factory.connect(
      ftsoManagerAddress,
      library.getSigner()
    );
  }, [library, ftsoManagerAddress]);

  const initialStatus = useCallback(async () => {
    if (!MulticallAddress || !account || !ftsoManagerAddress || !FtsoManager || epoch < 1 || !chainId) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_ftsoManager',
        contractAddress: ftsoManagerAddress,
        abi: FtsoManagerJSON,
        calls: [
          { reference: "res_getCurrentPriceEpochData", methodName: "getCurrentPriceEpochData", methodParameters: [] },
          { reference: "res_rewardEpochDurationSeconds", methodName: "rewardEpochDurationSeconds", methodParameters: [] },
          { reference: "res_getRewardEpochVotePowerBlock", methodName: "getRewardEpochVotePowerBlock", methodParameters: [epoch] },
          { reference: "res_rewardEpochs", methodName: "rewardEpochs", methodParameters: [epoch] },
        ]
      }
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_ftsoManager = res.res_ftsoManager.callsReturnContext;

      let res_getCurrentPriceEpochData = res_ftsoManager[0].returnValues;
      let res_rewardEpochDurationSeconds = res_ftsoManager[1].returnValues[0];
      let res_getRewardEpochVotePowerBlock = res_ftsoManager[2].returnValues[0];
      let res_rewardEpochs = res_ftsoManager[3].returnValues;

      setCurrentTime(Number(BigNumber.from(res_getCurrentPriceEpochData[4])));
      setCurrentPriceEpoch(Number(BigNumber.from(res_getCurrentPriceEpochData[0])));
      let x = Number(BigNumber.from(res_getCurrentPriceEpochData[2])) - Number(BigNumber.from(res_getCurrentPriceEpochData[4]));
      let remain = x < 90 ? x : x - 90;

      setRemainPriceEpochTime(remain);
      if (res_rewardEpochDurationSeconds) setDuration(Number(BigNumber.from(res_rewardEpochDurationSeconds)));
      setVPBlock(Number(BigNumber.from(res_getRewardEpochVotePowerBlock)));
      setStartTime(Number(BigNumber.from(res_rewardEpochs[2])));

      setPriceEpochStartTimeStamp(Number(BigNumber.from(res_getCurrentPriceEpochData[1])));
      setPriceEpochEndTimeStamp(Number(BigNumber.from(res_getCurrentPriceEpochData[2])));

    } catch (error) {
      console.log(error);
    }

  }, [MulticallAddress, account, ftsoManagerAddress, FtsoManager, epoch, chainId])

  const getRemainPriceEpochTime = useCallback(async () => {
    if (!FtsoManager) return;
    const res = await FtsoManager.getCurrentPriceEpochData();
    let x = res.priceEpochEndTimestamp.toNumber() - res.currentTimestamp.toNumber();
    let remain = x < 90 ? x : x - 90;
    setCurrentPriceEpoch(res.priceEpochId.toNumber())
    setRemainPriceEpochTime(remain);
  }, [FtsoManager])

  const getPriceEpochData = useCallback(async () => {
    if (!FtsoManager) return;
    const res = await FtsoManager.getCurrentPriceEpochData();
    setPriceEpochStartTimeStamp(res.priceEpochStartTimestamp.toNumber());
    setPriceEpochEndTimeStamp(res.priceEpochEndTimestamp.toNumber());
  }, [FtsoManager])

  useEffect(() => {
    initialStatus();
  }, [MulticallAddress, account, ftsoManagerAddress, FtsoManager, epoch, chainId])

  useEffect(() => {
    if (vpBlock < 1 || !vpToken || !account) return;
    vpToken.balanceOfAt(account, vpBlock).then(res => setActiveAmount(parseFloat(formatEther(res))));
  }, [vpBlock, vpToken, account])


  useEffect(() => {
    if (!FtsoManager) return;
    FtsoManager.getCurrentRewardEpoch().then(res => setEpoch(res.toNumber()));
  }, [FtsoManager]);

  return {
    epoch,
    startTime,
    duration,
    activeAmount,
    currentTime,
    remainPriceEpochTime,
    currentPriceEpoch,
    vpBlock,
    FtsoManager,
    priceEpochEndTimeStamp,
    priceEpochStartTimeStamp,
    getRemainPriceEpochTime,
    getPriceEpochData
  };
};

export const useClaimSetupManager = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [claimExecutorsList, setClaimExecutorsList] = useState<IClaimExecutor[]>([]);
  const [executorsAddresses, setExecutorsAddresses] = useState<string[]>([]);
  const [claimExecutors, setClaimExecutors] = useState<string[]>([]);

  const CSMAddress = useContractAddress("ClaimSetupManager");

  const MulticallAddress = useContractAddress("MultiContract")

  const CSM = useMemo(() => {
    if (!library || !CSMAddress) return undefined;
    return ClaimSetupManager__factory.connect(CSMAddress, library.getSigner());
  }, [library, CSMAddress]);

  useEffect(() => {
    if (!account || !CSM) return;

    CSM.getRegisteredExecutors(0, 20).then(res => setExecutorsAddresses(res._registeredExecutors));
    update();
  }, [account, CSM])

  useEffect(() => {
    getExecutorsFee();
  }, [account, CSMAddress, executorsAddresses, MulticallAddress])

  const update = useCallback(async () => {
    if (!account || !CSM) return;
    CSM.claimExecutors(account).then(res => setClaimExecutors(res));
  }, [account, CSM])

  const getExecutorsFee = useCallback(async () => {
    if (!account || !CSMAddress || executorsAddresses.length == 0 || !MulticallAddress || !chainId) return;

    let executorsList: IClaimExecutor[] = [];

    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [{
      reference: 'res_executorsFee',
      contractAddress: CSMAddress,
      abi: CSMJSON,
      calls: [...executorsAddresses.map((item => { return { reference: 'res_executorsFee', methodName: 'getExecutorCurrentFeeValue', methodParameters: [item] } }))]
    }]

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const res = results.results;

    executorsAddresses.forEach((item, index) => {
      let res_executorsFee = res.res_executorsFee.callsReturnContext[index].returnValues[0];
      executorsList.push({
        executorAddr: item,
        fee: Number(formatEther(res_executorsFee))
      });
    })

    setClaimExecutorsList(executorsList);

  }, [account, CSMAddress, executorsAddresses, MulticallAddress, chainId])

  const setClaimExecutor = useCallback(async (addresses: string[], fee: number) => {
    if (!account || !CSM) return;

    try {
      const wei = parseEther(fee.toFixed(18));
      const tx = await CSM.setClaimExecutors(addresses, { value: wei });
      await tx.wait();
      update();
      notifySuccess("Auto Claim Activated Successfully");
    } catch (error) {
      console.log(error);
      notifyError("AutoClaim Configuration Failed");
    }
  }, [account, CSM])

  return {
    setClaimExecutor,
    claimExecutorsList,
    claimExecutors,
  }
}

export const useDistributionToDelegators = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [totalCalimedFlareDrop, setTotalClaimedFlareDrop] = useState("");
  const [currentMon, setCurrentMon] = useState<number | undefined>(undefined);
  const [claimableFlareDropMonths, setClaimableFlareDropMonths] = useState<IClaimableFlareDropMonths | undefined>(undefined);
  const [nextClaimableMonth, setNextClaimableMonth] = useState<number | undefined>(undefined);
  const [claimableFlareDropAmounts, setClaimableFlareDropAmounts] = useState<IClaimableFlareDropAmount[]>([]);
  const [claimableFlareToken, setClaimableFlareToken] = useState(0);
  const [DistAddress, setDistAddress] = useState<string | undefined>(undefined);

  const MulticallAddress = useContractAddress("MultiContract");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 14) setDistAddress("0x9c7A4C83842B29bB4A082b0E689CB9474BD938d0");
    if (chainId === 114) setDistAddress("0xbd33bDFf04C357F7FC019E72D0504C24CF4Aa010");
  }, [chainId])


  const Dist = useMemo(() => {
    if (!library || !DistAddress) return undefined;
    return DistributionToDelegators__factory.connect(DistAddress, library.getSigner());
  }, [library, DistAddress]);

  const initialStatus = useCallback(async () => {
    if (!account || !DistAddress || !Dist || !MulticallAddress || !chainId) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_dist',
        contractAddress: DistAddress,
        abi: DistJSON,
        calls: [
          { reference: "res_getClaimableMonths", methodName: "getClaimableMonths", methodParameters: [] },
          { reference: "res_getCurrentMonth", methodName: "getCurrentMonth", methodParameters: [] },
          { reference: "res_nextClaimableMonth", methodName: "nextClaimableMonth", methodParameters: [account] },
        ]
      }
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_dist = res.res_dist.callsReturnContext;
      let res_getClaimableMonths = res_dist[0].returnValues;
      let res_getCurrentMonth = res_dist[1].returnValues[0];
      let res_nextClaimableMonth = res_dist[2].returnValues[0];

      setClaimableFlareDropMonths({
        ...claimableFlareDropMonths,
        startMonth: Number(BigNumber.from(res_getClaimableMonths[0])),
        endMonth: Number(BigNumber.from(res_getClaimableMonths[1]))
      })
      setCurrentMon(Number(BigNumber.from(res_getCurrentMonth)));
      setNextClaimableMonth(Number(BigNumber.from(res_nextClaimableMonth)));
    } catch (error) {
      console.log(error);
    }

  }, [account, DistAddress, Dist, MulticallAddress, chainId])

  useEffect(() => {
    initialStatus();
  }, [account, DistAddress, Dist, MulticallAddress, chainId])

  const getClaimableAmount = useCallback(async () => {
    if (!account || !Dist || !DistAddress || !claimableFlareDropMonths || !nextClaimableMonth || !chainId) return;

    let startMon = claimableFlareDropMonths.startMonth;
    let endMon = claimableFlareDropMonths.endMonth;

    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    let temp = Array.from({ length: endMon - nextClaimableMonth + 1 }, (v, i) => nextClaimableMonth + i);

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_dist',
        contractAddress: DistAddress,
        abi: DistJSON,
        calls: [...temp.map((item, index) => {
          return { reference: 'res_getClaimableAmount', methodName: 'getClaimableAmountOf', methodParameters: [account, item] }
        })]
      }
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_getClaimableAmount = res.res_dist.callsReturnContext;
      let total = 0;
      temp.forEach((item, index) => { total += parseFloat(formatEther(res_getClaimableAmount[index].returnValues[0])) })
      setClaimableFlareToken(total);
    } catch (error) {
      console.log(error)
    }
  }, [account, Dist, DistAddress, claimableFlareDropMonths, nextClaimableMonth, chainId])

  useEffect(() => {
    getClaimableAmount();
  }, [account, Dist, DistAddress, claimableFlareDropMonths, nextClaimableMonth, chainId])

  const claimAllFlareDrop = useCallback(async (wrap: boolean) => {
    if (!account || !Dist || !nextClaimableMonth) return;
    try {
      const response = await Dist.claim(account, account, nextClaimableMonth, wrap);
      await response.wait();
      const res = await Dist.nextClaimableMonth(account);
      setNextClaimableMonth(res.toNumber());
      notifySuccess(`Claimed Flare Drop for ${nextClaimableMonth}.`);
    } catch (error) {
      console.log(error);
      notifyError("Flare Drop Claim Failed")
    }
  }, [account, Dist, nextClaimableMonth])

  return {
    claimableFlareDropAmounts,
    totalCalimedFlareDrop,
    claimableFlareToken,
    claimAllFlareDrop,
    claimableFlareDropMonths,
    DistAddress,
    currentMon,
    Dist,
  }
}


export const useArc = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [arcIdsOfAccount, setArcIdsOfAccount] = useState<number[] | undefined>(undefined);
  const [walletUris, setWalletUris] = useState<string[] | undefined>(undefined);
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const [baseUri, setBaseUri] = useState<string | undefined>(undefined);
  const [freeOrderable, setFreeOrderable] = useState<Boolean | undefined>(undefined);
  const [turnOnWhitelist, setTurnOnWhitelist] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [payWithNativeToken, setPayWithNativeToken] = useState<Boolean | undefined>(undefined);
  const [mintedAmount, setMintedAmount] = useState(0);
  const [mintUSDCost, setMintUSDCost] = useState(0);
  const [currentNativeTokenPrice, setCurrentNativeTokenPrice] = useState(1);
  const [claimableEpochsCntForOrderer, setClaimableEpochsCntForOrderer] = useState(0);
  const [deliveringOrderCnt, setDeliveringOrderCnt] = useState(0);
  const [totalOrderCnt, setTotalOrderCnt] = useState(0);
  const [orderedEpochId, setOrderedEpochId] = useState<number[]>([]);
  const [totalOrdered, setTotalOrdered] = useState(0);
  const [orderableCount, setOrderableCount] = useState(0);
  const [arcAddress, setArcAdddress] = useState<string | undefined>(undefined);
  // const arcAddress = useContractAddress("Arc");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 114) setArcAdddress(C2_ARC_ADD);
  }, [chainId])

  const nativeTokenSymbol = chainId == 114 ? "C2FLR" : "FLR";

  const MulticallAddress = useContractAddress("MultiContract");

  const arc = useMemo(() => {
    if (!library || !arcAddress) return undefined;
    return Token1__factory.connect(arcAddress, library.getSigner());
  }, [library, arcAddress]);

  const getTotalOrdered = useCallback(async () => {
    if (!arc || !account) return;
    arc.getTotalOrdered().then(res => setTotalOrdered(res))
    arc.getOrderable(account).then(res => setOrderableCount(res.toNumber()));
    arc.totalSupply().then(res => setMintedAmount(res.toNumber()));
  }, [arc, account])

  useEffect(() => {
    getTotalOrdered();
  }, [arc, account])

  const initialStatus = useCallback(async () => {
    if (!arc || !account || !arcAddress || !MulticallAddress || !chainId) return;

    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_ARCNFT',
        contractAddress: arcAddress,
        abi: ARCJSON.abi,
        calls: [
          { reference: "res_totalSupply", methodName: "totalSupply", methodParameters: [] },
          { reference: "res_MAX_SUPPLY", methodName: "MAX_SUPPLY", methodParameters: [] },
          { reference: "res_mintUSDCost", methodName: "mintUSDCost", methodParameters: [] },
          { reference: "res_walletOfOwner", methodName: "walletOfOwner", methodParameters: [account] },
          { reference: "res_getOrders", methodName: "getOrders", methodParameters: [account] },
          { reference: "res_getClaimableOrderCount", methodName: "getClaimableOrderCount", methodParameters: [account] },
          { reference: "res_getDeliveringOrderCount", methodName: "getDeliveringOrderCount", methodParameters: [account] },
          { reference: "res_turnOnWhitelist", methodName: "turnOnWhitelist", methodParameters: [] },
          { reference: "res_baseURI", methodName: "baseURI", methodParameters: [] },
          { reference: "res_getCurrentTokenPriceBySymbol", methodName: "getCurrentTokenPriceBySymbol", methodParameters: [nativeTokenSymbol] },
          { reference: "res_willUseFLRCost", methodName: "willUseFLRCost", methodParameters: [] },
          { reference: "res_whitelisted", methodName: "whitelisted", methodParameters: [account] }]
      }
    ]
    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_arc = res.res_ARCNFT.callsReturnContext;

      let res_totalSupply = res_arc[0].returnValues[0];
      let res_MAX_SUPPLY = res_arc[1].returnValues[0];
      let res_mintUSDCost = res_arc[2].returnValues[0];
      let res_walletOfOwner = res_arc[3].returnValues;
      let res_getOrders = res_arc[4].returnValues;
      let res_getClaimableOrderCount = res_arc[5].returnValues[0];
      let res_getDeliveringOrderCount = res_arc[6].returnValues[0];
      let res_turnOnWhitelist = res_arc[7].returnValues[0];
      let res_baseURI = res_arc[8].returnValues[0];
      let res_getCurrentTokenPriceBySymbol = res_arc[9].returnValues;
      let res_willUseFLRCost = res_arc[10].returnValues[0];
      let res_whitelisted = res_arc[11].returnValues[0];

      // setMintedAmount(Number(BigNumber.from(res_totalSupply)));
      setTotalSupply(Number(BigNumber.from(res_MAX_SUPPLY)));
      setMintUSDCost(Number(formatEther(res_mintUSDCost)));
      if (res_walletOfOwner.length > 0) {
        setArcIdsOfAccount(res_walletOfOwner.map((id: BigNumber) => Number(BigNumber.from(id))));
        setBalance(res_walletOfOwner.length);
      };
      setTotalOrderCnt(res_getOrders.length)
      setOrderedEpochId(res_getOrders);
      setClaimableEpochsCntForOrderer(Number(BigNumber.from(res_getClaimableOrderCount)));
      setDeliveringOrderCnt(Number(BigNumber.from(res_getDeliveringOrderCount)));
      setTurnOnWhitelist(res_turnOnWhitelist);
      setBaseUri(res_baseURI);
      setCurrentNativeTokenPrice(Number(BigNumber.from(res_getCurrentTokenPriceBySymbol[0])) / Math.pow(10, Number(BigNumber.from(res_getCurrentTokenPriceBySymbol[1]))));
      setPayWithNativeToken(res_willUseFLRCost);
      if (Number(BigNumber.from(res_whitelisted)) > 0) setFreeOrderable(true)
    } catch (e) {
      console.log(e)
    }
  }, [arc, account, arcAddress, MulticallAddress, chainId])

  useEffect(() => {
    initialStatus();
  }, [arc, account, arcAddress, MulticallAddress, chainId])

  useEffect(() => {
    if (!arcIdsOfAccount || arcIdsOfAccount.length < 1 || !arc || !baseUri) {
      setWalletUris(undefined);
      return;
    }
    getUris(arcIdsOfAccount)
      .then((uris) => {
        setWalletUris(uris);
      }).catch((e) => {
        setWalletUris(undefined);
        notifyError("Cannot read images of your Arc NFTs");
      });
  }, [arcIdsOfAccount, arc, baseUri]);

  const getOrderingStatus = useCallback(async () => {
    if (!arc || !account) return;
    arc.getClaimableOrderCount(account).then(res => { setClaimableEpochsCntForOrderer(res) });
    arc.getDeliveringOrderCount(account).then(res => { setDeliveringOrderCnt(res) });
  }, [arc, account])

  const getUris = useCallback(
    async (ids: number[]) => {
      if (!arc || !baseUri) return undefined;
      const uris = [];
      try {
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          if (id === 0) continue;
          const metadata = (
            await (
              await fetch(
                baseUri + id + ".json"
              )
            ).json()
          )["image"];
          uris.push(metadata);
        }
      } catch (error) {
        console.log(error)
        return undefined;
      }
      return uris;
    },
    [arc, baseUri]
  );

  const claimByOrderer = useCallback(async () => {
    if (!arc || (claimableEpochsCntForOrderer == 0) || !account) return;
    try {
      const tx = await arc.claimByOrderer();
      await tx.wait();
      notifySuccess("Claimed Your Order");
      const bal = balance ? balance + claimableEpochsCntForOrderer : claimableEpochsCntForOrderer;
      setBalance(bal);
      setClaimableEpochsCntForOrderer(0);
      getTotalOrdered();
    } catch (error) {
      notifyError("Your Order Claim Failed")
      console.log(error);
    }

  }, [arc, claimableEpochsCntForOrderer, account]);

  const createFreeOrder = useCallback(async () => {
    if (!arc || !account) return;
    try {
      const tx = await arc.createOrderForFree();
      await tx.wait();
      notifySuccess("1 ARC Ordered For Free");
      setFreeOrderable(false);
      getTotalOrdered();
    } catch (error) {
      notifyError("Free Order Failed");
      console.log(error);
    }
  }, [arc, account]);

  const createPaidOrderWithNativeToken = useCallback(async (count: number, payableToken?: string) => {
    if (!arc || !account || mintUSDCost === 0 || !library) return;
    try {
      if (!payableToken) {
        const response = await arc.getCurrentTokenPriceBySymbol(nativeTokenSymbol);
        let price = response._price.toNumber() / Math.pow(10, response._assetPriceUsdDecimals.toNumber());
        const wei = parseEther(String((count * (mintUSDCost / price + 0.005)).toFixed(3)));
        setCurrentNativeTokenPrice(price);
        const tx = await arc.createOrderWithFLR(count, { value: wei });
        await tx.wait();
      } else {
        const symbol = await arc.payableTokenSymbolOf(payableToken);
        const response = await arc.getCurrentTokenPriceBySymbol(symbol);
        let price = response._price.toNumber() / Math.pow(10, response._assetPriceUsdDecimals.toNumber());
        const ERC20 = ERC20__factory.connect(payableToken, library.getSigner());
        const allowance = await ERC20.allowance(account, C2_ARC_ADD);
        const allowEther = parseFloat(formatEther(allowance));
        if (allowEther < count * (mintUSDCost / price + 0.005)) {
          console.log(parseEther(String((count * (mintUSDCost / price) + 0.005).toFixed(3))))
          const tx_approve = await ERC20.approve(C2_ARC_ADD, parseEther(String((count * (mintUSDCost / price + 0.005)).toFixed(3))));
          await tx_approve.wait();
        }
        const tx = await arc.createOrderWithPayableTokens(payableToken, count);
        await tx.wait();
      }
      setDeliveringOrderCnt(deliveringOrderCnt + count);
      await getTotalOrdered();
      notifySuccess(count + " ARCs Ordered");
    } catch (error: any) {
      notifyError("Paid Order Failed")
      console.log(error)
    }
  }, [arc, account, mintUSDCost, library]);

  return {
    arcIdsOfAccount,
    totalSupply,
    mintedAmount,
    mintUSDCost,
    freeOrderable,
    turnOnWhitelist,
    balance,
    walletUris,
    totalOrdered,
    orderableCount,
    getOrderingStatus,
    getUris,
    claimByOrderer,
    createFreeOrder,
    createPaidOrderWithNativeToken,
    currentNativeTokenPrice,
    nativeTokenSymbol,
    claimableEpochsCntForOrderer,
    deliveringOrderCnt,
    orderedEpochId,
    totalOrderCnt
  };
};

export const useArcRewardManager = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [claimableEpochs, setClaimableEpochs] = useState<number[] | undefined>(undefined);
  const [claimableIds, setClaimableIds] = useState<number[] | undefined>(undefined);
  const [ordered, setOrdered] = useState<Boolean | undefined>(undefined);
  const [orderable, setOrderable] = useState<Boolean | undefined>(undefined);
  const [totalClaimed, setTotalClaimed] = useState<Number | undefined>(undefined);
  const [rankThres, setRankThres] = useState(0);
  const [thresHold, setThresHold] = useState(0);
  const [mintableRankThres, setMintableRankThres] = useState(0);
  const [myRank, setMyRank] = useState(0);
  const [totalRewardArcOrdersCnt, setTotalRewardArcOrdersCnt] = useState(0);
  const [rankInfoes, setRankInfoes] = useState<IRankInfo[]>([]);
  const [minVPRange, setMinVPRange] = useState(0);
  const [maxVPRange, setMaxVPRange] = useState(0);
  const [epochInterval, setEpochInterval] = useState(0);
  const [randomAssignedFtsoProvider, setRandomAssignedFtsoProvider] = useState("");
  const [randomUpcomingFtsoProvider, setRandomUpcomingFtsoProvider] = useState("");
  const [startRewardEpochForRandomFtso, setStartRewardEpochForRandomFtso] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [arcRewardManagerAddress, setArcRewardManagerAddress] = useState<string | undefined>(undefined);

  // const arcRewardManagerAddress = useContractAddress("ArcNFTRewardManager");
  const MulticallAddress = useContractAddress("MultiContract");
  // const provider = new ethers.providers.JsonRpcProvider(chainId === 14 ? FlareRpc : (chainId === 19 ? SongbirdRpc : Coston2Rpc));

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 114) setArcRewardManagerAddress(C2_NFTRewardManager_ADD);
  }, [chainId])

  const arcRewardManager = useMemo(() => {
    if (!library || !arcRewardManagerAddress) return undefined;
    return RM1__factory.connect(arcRewardManagerAddress, library.getSigner());
  }, [library, arcRewardManagerAddress]);

  const createOrder = useCallback(async () => {
    if (!orderable || !arcRewardManager) return;
    try {
      const tx = await arcRewardManager.order();
      await tx?.wait();
      setOrdered(true);
      setOrderable(false);
      notifySuccess("Order Success For Epoch");
    } catch (e) {
      notifyError("You cannot create order");
    }
  }, [arcRewardManager, orderable]);

  const claimReward = useCallback(
    async (epoch: number) => {
      if (!account || !arcRewardManager) return;
      try {
        const tx = await arcRewardManager.claimReward(account, epoch);
        await tx.wait();
        let temp = claimableEpochs;
        let ids = claimableIds;
        if (temp && temp.length > 1) {
          temp.shift();
          setClaimableEpochs([...temp]);
        } else setClaimableEpochs(undefined);

        if (ids && ids.length > 1) {
          ids.shift();
          setClaimableIds([...ids]);
        } else setClaimableIds(undefined);

        notifySuccess("Claimed A Reward NFT");
      } catch (e) {
        notifyError("Claiming NFT Failed");
      }
    },
    [account, arcRewardManager]
  );

  const getAllIds = useCallback(async () => {
    if (!account || !arcRewardManagerAddress || !claimableEpochs || claimableEpochs.length < 1 || !MulticallAddress || !chainId) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_claimableIds',
        contractAddress: arcRewardManagerAddress,
        abi: ARCNFTRMJSON.abi,
        calls: [...claimableEpochs.map((item => { return { reference: 'res_claimableIds', methodName: 'claimableId', methodParameters: [account, item] } }))]
      }
    ]

    let ids: number[] = [];
    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;

      claimableEpochs.forEach((id, index) => {
        const res_claimableIds = res.res_claimableIds.callsReturnContext[index].returnValues[0];
        ids.push(Number(BigNumber.from(res_claimableIds)));
      })
      setClaimableIds(ids);
    } catch (e) {
      console.log(e)
    }

  }, [claimableEpochs, account, arcRewardManagerAddress, MulticallAddress, chainId]);

  useEffect(() => {
    getAllIds();
  }, [claimableEpochs, account, arcRewardManagerAddress, MulticallAddress, chainId])

  const getOrderedStatus = useCallback((epoch: number) => {
    if (!account || !arcRewardManager || (epoch < 1)) return;
    arcRewardManager.orders(epoch, account).then(res => {
      if (parseFloat(formatEther(res)) !== 0) setOrdered(true);
    });
  }, [account, arcRewardManager])

  const getOrderedStatusOfEpoch = useCallback(async (epoch: number) => {
    if (!account || !arcRewardManager) return;
    return arcRewardManager
      .orders(BigNumber.from(epoch), account)
      .then((order) => { return Number(formatEther(order)) === 0 ? false : true });
  }, [account, arcRewardManager]);

  const getMyRank = useCallback((epoch: any) => {
    if (!account || !arcRewardManager) return;
    arcRewardManager.getRewardARCOrdersCount(epoch).then(res => { setTotalRewardArcOrdersCnt(res); })
      .catch(e => { console.log(e); })
    arcRewardManager.getRank(account, epoch).then(res => { setMyRank(res); })
      .catch(e => { console.log(e); })
  }, [account, arcRewardManager]);

  const getRankInfoes = useCallback((epoch: any, rankArr: any) => {
    if (!account || !arcRewardManager) return;
    setIsLoading(true);
    arcRewardManager.getRankInfoes(BigNumber.from(epoch), rankArr).then((res) => {
      let rankInfoesRes = res.map((ele, index) => {
        return {
          _rank: ele._rank.toString(),
          _address: ele._delegator,
          _amount: ele._amount.toString()
        }
      })
      return rankInfoesRes;
    }).then((res) => {
      setRankInfoes(res);
      setIsLoading(false);
    }).catch(e => { console.log(e); setIsLoading(false); })
  }, [account, arcRewardManager]);

  const getInfoesForTargetEpoch = useCallback((epoch: any) => {
    if (!account || !arcRewardManager || (epoch < 1)) return;
    getMyRank(epoch);
    getRankInfoes(epoch, Array.from({ length: ItemsPerPage }, (v, i) => i + 1))
  }, [account, arcRewardManager])

  const initialStatus = useCallback(async () => {
    if (!account || !arcRewardManager || !arcRewardManagerAddress || !MulticallAddress || !chainId) return;

    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true,
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_ARCReward',
        contractAddress: arcRewardManagerAddress,
        abi: ARCNFTRMJSON.abi,
        calls: [
          { reference: "res_getRandomFtsoProvider", methodName: "getRandomFtsoProvider", methodParameters: [] },
          { reference: "res_startRewardEpochForRandomFtso", methodName: "startRewardEpochForRandomFtso", methodParameters: [] },
          { reference: "res_lowerboundOfVPRange", methodName: "lowerboundOfVPRange", methodParameters: [] },
          { reference: "res_upperboundOfVPRange", methodName: "upperboundOfVPRange", methodParameters: [] },
          { reference: "res_intervalEpochsForRandomFtso", methodName: "intervalEpochsForRandomFtso", methodParameters: [] },
          { reference: "res_getOrderable", methodName: "getOrderable", methodParameters: [account] },
          { reference: "res_getClaimableEpochs", methodName: "getClaimableEpochs", methodParameters: [account] },
          { reference: "res_totalClaimed", methodName: "totalClaimed", methodParameters: [account] },
          { reference: "res_rankThres", methodName: "rankThres", methodParameters: [] },
          { reference: "res_mintableRankThres", methodName: "mintableRankThres", methodParameters: [] },
          { reference: "res_threshold", methodName: "threshold", methodParameters: [] }]
      }
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_arc = res.res_ARCReward.callsReturnContext;

      let res_getRandomFtsoProvider = res_arc[0].returnValues;
      let res_startRewardEpochForRandomFtso = res_arc[1].returnValues[0];
      let res_lowerboundOfVPRange = res_arc[2].returnValues[0];
      let res_upperboundOfVPRange = res_arc[3].returnValues[0];
      let res_intervalEpochsForRandomFtso = res_arc[4].returnValues[0];
      let res_getOrderable = res_arc[5].returnValues[0];
      let res_getClaimableEpochs = res_arc[6].returnValues;
      let res_totalClaimed = res_arc[7].returnValues[0];
      let res_rankThres = res_arc[8].returnValues[0];
      let res_mintableRankThres = res_arc[9].returnValues[0];
      let res_threshold = res_arc[10].returnValues[0];


      setRandomAssignedFtsoProvider(res_getRandomFtsoProvider[0]);
      setRandomUpcomingFtsoProvider(res_getRandomFtsoProvider[1]);
      setStartRewardEpochForRandomFtso(res_startRewardEpochForRandomFtso);
      setMinVPRange(Number(BigNumber.from(res_lowerboundOfVPRange)));
      setMaxVPRange(Number(BigNumber.from(res_upperboundOfVPRange)));
      setEpochInterval(res_intervalEpochsForRandomFtso);
      setOrderable(res_getOrderable);
      if (res_getClaimableEpochs.length > 0) setClaimableEpochs(res_getClaimableEpochs);
      setTotalClaimed(Number(BigNumber.from(res_totalClaimed)));
      setRankThres(res_rankThres);
      setMintableRankThres(res_mintableRankThres);
      setThresHold(parseFloat(formatEther(res_threshold)));
    } catch (error) {
      console.log(error);
    }
  }, [account, arcRewardManager, arcRewardManagerAddress, MulticallAddress, chainId])

  useEffect(() => {
    initialStatus();
  }, [arcRewardManager, account, arcRewardManagerAddress, MulticallAddress, chainId])

  return {
    claimableEpochs,
    claimableIds,
    ordered,
    claimReward,
    totalClaimed,
    createOrder,
    orderable,
    rankThres,
    mintableRankThres,
    myRank,
    totalRewardArcOrdersCnt,
    isLoading,
    minVPRange,
    maxVPRange,
    epochInterval,
    thresHold,
    randomAssignedFtsoProvider,
    randomUpcomingFtsoProvider,
    rankInfoes,
    getOrderedStatus,
    getRankInfoes,
    getInfoesForTargetEpoch,
    getOrderedStatusOfEpoch,
    startRewardEpochForRandomFtso
  };
};

export const useArcVault = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [isLoading, setIsLoading] = useState(false);
  const [wNatBalanceOfAccount, setWNatBalanceOfAccount] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalSupply, setTotalSupply] = useState(1);
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const [delegatable, setDelegatable] = useState(false);
  const [meanWorthOfNFT, setMeanWorthOfNFT] = useState(0);
  const [delegatedAmount, setDelegatedAmount] = useState(0);
  const [worthsByIdInVault, setWorthsByIdInVault] = useState<number[] | undefined>(undefined);
  const [lockedAmountOfVault, setLockedAmountOfVault] = useState(0);
  const [pendingRewardOfVault, setPendingRewardOfVault] = useState(0);
  const [delegationsOfVault, setDelegationsOfVault] = useState<IAmountOfDelegation[]>([]);
  const [remainVaultUnlockTime, setRemainVaultUnlockTime] = useState(0);
  const [claimableRewardOfVault, setClaimableRewardOfVault] = useState(0);
  const [nextClaimableMonthOfVault, setNextClaimableMonthOfVault] = useState(0);
  const [claimableFlareDropOfVault, setClaimableFlareDropOfVault] = useState(0);
  const [VISTokenOfAccount, setVISTokenOfAccount] = useState(0);
  const [claimableAmountOfAccountInVault, setClaimableAmountOfAccountInVault] = useState(0);
  const [claimableAmount1OfAccountInVault, setClaimableAmount1OfAccountInVault] = useState(0);

  const [visToken, setVisToken] = useState<string | undefined>(undefined);
  const [ArcVaultAdd, setArcVaultAdd] = useState<string | undefined>(undefined);

  const [VISTokenOfVault, setVISTokenOfVault] = useState<TokenInfo>(initTokenInfo);
  const [wFLRTokenOfVault, setWFLRTokenOfVault] = useState<TokenInfo>(initTokenInfo);

  // const ArcVaultAdd = useContractAddress("Archetype_Vault");

  const wNatAddress = useContractAddress("WNat");

  // const visToken = useContractAddress("VISERC20");

  const MulticallAddress = useContractAddress("MultiContract")

  const { epoch, vpBlock } = useCurrentRewardEpoch();

  const { rewardManager, rewardManagerAddress } = useRewardManager();

  const { Dist, currentMon } = useDistributionToDelegators();

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 114) {
      setVisToken(C2_VISTOKEN_ADD);
      setArcVaultAdd(C2_Vault_ADD);
    }
  }, [chainId])

  const ArcVault = useMemo(() => {
    if (!library || !ArcVaultAdd) return;
    return Archetype_Vault__factory.connect(
      ArcVaultAdd,
      library.getSigner()
    )
  }, [library, ArcVaultAdd])

  const initialStatus = useCallback(async () => {
    if (!ArcVaultAdd || !wNatAddress || !visToken || !rewardManagerAddress || !MulticallAddress || !chainId || !account || epoch < 1 || vpBlock < 1) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: chainId == 114 ? Coston2Provider : (chainId === 14) ? FlareProvider : SongbirdProvider,
      tryAggregate: true
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_arcVault',
        contractAddress: ArcVaultAdd,
        abi: ARCVaultJSON.abi,
        calls: [
          { reference: "res_getTokenInfo1", methodName: "getTokenInfo", methodParameters: [wNatAddress] },
          { reference: "res_getTokenInfo2", methodName: "getTokenInfo", methodParameters: [visToken] },
          { reference: "res_delegatable", methodName: "delegatable", methodParameters: [] },
          { reference: "res_getClaimableAmount2", methodName: "getClaimableAmount", methodParameters: [account, visToken] },
          { reference: "res_getClaimableAmount1", methodName: "getClaimableAmount", methodParameters: [account, wNatAddress] },
          { reference: "res_maxSupply", methodName: "maxSupply", methodParameters: [] },
        ]
      },
      {
        reference: 'res_vpToken',
        contractAddress: wNatAddress,
        abi: WNatJSON.abi,
        calls: [
          { reference: "res_delegatesOf", methodName: "delegatesOf", methodParameters: [ArcVaultAdd] },
          { reference: "res_balanceOfAt", methodName: "balanceOfAt", methodParameters: [ArcVaultAdd, vpBlock] },
          { reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [account] },
        ]
      },
      {
        reference: 'res_visToken',
        contractAddress: visToken,
        abi: ERC20JSON.abi,
        calls: [{ reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [account] },]
      },
      {
        reference: 'res_rewardManager',
        contractAddress: rewardManagerAddress,
        abi: FtsoRewardManagerJSON.abi,
        calls: [{ reference: "res_getStateOfRewards", methodName: "getStateOfRewards", methodParameters: [ArcVaultAdd, epoch] },]
      },
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_getTokenInfo1 = res.res_arcVault.callsReturnContext[0].returnValues;
      const res_getTokenInfo2 = res.res_arcVault.callsReturnContext[1].returnValues;
      const res_delegatable = res.res_arcVault.callsReturnContext[2].returnValues[0];
      const res_getClaimableAmount2 = res.res_arcVault.callsReturnContext[3].returnValues[0];
      const res_getClaimableAmount1 = res.res_arcVault.callsReturnContext[4].returnValues[0];
      const res_maxSupply = res.res_arcVault.callsReturnContext[5].returnValues[0];
      const res_delegatesOf = res.res_vpToken.callsReturnContext[0].returnValues;
      const res_balanceOfAt = res.res_vpToken.callsReturnContext[1].returnValues[0];
      const res_balanceOfWNat = res.res_vpToken.callsReturnContext[2].returnValues[0];
      const res_balanceOf = res.res_visToken.callsReturnContext[0].returnValues[0];
      const res_getStateOfRewards = res.res_rewardManager.callsReturnContext[0].returnValues;

      let currentBalance = parseFloat(formatEther(res_getTokenInfo1[0]));
      let claimedBalance = parseFloat(formatEther(res_getTokenInfo1[1][1]));
      let remain = Number(BigNumber.from(res_getTokenInfo1[1][3])) - Math.floor(new Date().getTime() / 1000);
      setRemainVaultUnlockTime(remain);
      let mean = (currentBalance + claimedBalance) / 11111;
      setMeanWorthOfNFT(mean);
      setTotalVolume(currentBalance + claimedBalance);

      const temp1: TokenInfo = initTokenInfo;
      temp1.balance = parseFloat(formatEther(res_getTokenInfo1[0]));
      temp1.name = res_getTokenInfo1[1][0];
      temp1.claimedAmount = parseFloat(formatEther(res_getTokenInfo1[1][1]));
      temp1.remainPerNFT = parseFloat(formatEther(res_getTokenInfo1[1][2]));
      temp1.unlockTimestamp = Number(BigNumber.from(res_getTokenInfo1[1][3]));
      temp1.claimableAmount = parseFloat(formatEther(res_getClaimableAmount1));
      setWFLRTokenOfVault({ ...temp1 });

      const temp2: TokenInfo = initTokenInfo;
      temp2.balance = parseFloat(formatEther(res_getTokenInfo2[0]));
      temp2.name = res_getTokenInfo2[1][0];
      temp2.claimedAmount = parseFloat(formatEther(res_getTokenInfo2[1][1]));
      temp2.remainPerNFT = parseFloat(formatEther(res_getTokenInfo2[1][2]));
      temp2.unlockTimestamp = Number(BigNumber.from(res_getTokenInfo2[1][3]));
      temp2.claimableAmount = parseFloat(formatEther(res_getClaimableAmount2));
      setVISTokenOfVault({ ...temp2 });
      setDelegatable(res_delegatable);
      setClaimableAmountOfAccountInVault(parseFloat(formatEther(res_getClaimableAmount2)));
      setClaimableAmount1OfAccountInVault(parseFloat(formatEther(res_getClaimableAmount1)));

      let unlocktimestamOfFLR = Number(BigNumber.from(res_getTokenInfo1[1][3]));
      let currenttimestamp = Math.floor(new Date().getTime() / 1000);

      if (unlocktimestamOfFLR < currenttimestamp && parseFloat(formatEther(res_getClaimableAmount1)) > 0) setIsWithdrawal(true)
      setTotalSupply(Number(BigNumber.from(res_maxSupply)));

      let allocated = 0;
      for (let i = 0; i < res_delegatesOf[0].length; i++) {
        allocated += Number(BigNumber.from(res_delegatesOf[1][i])) / 10000;
      }
      setDelegatedAmount(allocated * currentBalance);
      setVISTokenOfAccount(parseFloat(formatEther(res_balanceOf)));

      let totalAmount = 0;
      let tempArr = [];

      for (let i = 0; i < res_getStateOfRewards[0].length; i++) {
        let item = {
          address: res_getStateOfRewards[0][i],
          amount: Number(formatEther(res_getStateOfRewards[1][i]))
        }
        tempArr.push(item);
        totalAmount += item.amount;
      }
      setPendingRewardOfVault(totalAmount);
      setDelegationsOfVault(tempArr);
      setLockedAmountOfVault(parseFloat(formatEther(res_balanceOfAt)));
      setWNatBalanceOfAccount(parseFloat(formatEther(res_balanceOfWNat)));
    } catch (error) {
      console.log(error);
    }

  }, [ArcVaultAdd, wNatAddress, visToken, rewardManagerAddress, MulticallAddress, chainId, account, epoch, vpBlock])

  useEffect(() => {
    initialStatus();
  }, [ArcVaultAdd, wNatAddress, visToken, rewardManagerAddress, MulticallAddress, chainId, account, epoch, vpBlock])

  useEffect(() => {
    getUnclaimedReward();
  }, [rewardManager, ArcVaultAdd])

  const getUnclaimedReward = useCallback(async () => {
    if (!rewardManager || !ArcVaultAdd) return;
    try {
      const unclaimedEpochs = await rewardManager.getEpochsWithUnclaimedRewards(ArcVaultAdd);
      let reward = 0;

      for (let i = 0; i < unclaimedEpochs.length; i++) {
        const epoch = unclaimedEpochs[i].toNumber();
        const rewardAmounts = await rewardManager.getStateOfRewards(ArcVaultAdd, epoch);

        let pieceOfReward = rewardAmounts._rewardAmounts.reduce((a, b) => {
          return a + Number(formatEther(b));
        }, 0);
        reward += pieceOfReward;
      }
      setClaimableRewardOfVault(reward);
    } catch (error) {
      console.error(error);
    }

  }, [rewardManager, ArcVaultAdd])

  const claimRewardOfVault = useCallback(async () => {
    if (!rewardManager || !ArcVault) return;
    try {
      const tx = await ArcVault.claimReward();
      await tx.wait();
      getUnclaimedReward();
    } catch (error) {
      console.log(error)
    }

  }, [ArcVault, rewardManager])

  const delegate2TopDelegatees = useCallback(async () => {
    if (!ArcVault || !delegatable) return;
    try {
      const tx = await ArcVault.delegate();
      await tx.wait();
      notifySuccess("Delegation Success");
    } catch (error) {
      console.log(error)
      notifyError("Delegation Failed");
    }

  }, [ArcVault, delegatable])

  const claimFlareDropOfVault = useCallback(async () => {
    if (!ArcVault) return;
    try {
      const tx = await ArcVault.claimFlareDrop();
      await tx.wait();
      notifySuccess("Claim FlareDrop Success");
    } catch (error) {
      console.log(error)
      notifyError("Claim FlareDrop Failed");
    }

  }, [ArcVault])

  const withdrawShareOfVault = useCallback(async () => {
    if (!ArcVault || !(isWithdrawal || claimableAmountOfAccountInVault > 0)) return;
    try {
      const response = await ArcVault.withdrawAllTokens();
      await response.wait();
      if (isWithdrawal) {
        setWNatBalanceOfAccount(wNatBalanceOfAccount + claimableAmount1OfAccountInVault);
        setClaimableAmount1OfAccountInVault(0);
        setWFLRTokenOfVault({ ...wFLRTokenOfVault, claimableAmount: 0 });
      }
      setVISTokenOfAccount(VISTokenOfAccount + claimableAmountOfAccountInVault);
      setClaimableAmountOfAccountInVault(0);
      setVISTokenOfVault({ ...VISTokenOfVault, claimableAmount: 0 })
      notifySuccess("Withdrawal Success");
    } catch (error) {
      console.log(error)
      notifySuccess("Withdrawal Failed");
    }

  }, [ArcVault, isWithdrawal, claimableAmountOfAccountInVault])

  useEffect(() => {
    if (!Dist || !ArcVaultAdd) return;
    Dist.nextClaimableMonth(ArcVaultAdd).then(res => setNextClaimableMonthOfVault(res.toNumber()))
  }, [Dist, ArcVaultAdd])

  useEffect(() => {
    if (!Dist || !ArcVaultAdd || nextClaimableMonthOfVault < 1 || !currentMon) return;
    Promise.all(
      Array.from({ length: currentMon - nextClaimableMonthOfVault }, (v, i) => nextClaimableMonthOfVault + i)
        .map((item, ind) => Dist.getClaimableAmountOf(ArcVaultAdd, item).then(res => Number(formatEther(res))))
    ).then(res => {
      let totalClaimableFlareDrop = res.reduce((a, b) => {
        return a + b;
      }, 0);
      setClaimableFlareDropOfVault(totalClaimableFlareDrop);
    });
  }, [Dist, nextClaimableMonthOfVault, ArcVaultAdd, currentMon])

  return {
    totalVolume,
    remainVaultUnlockTime,
    lockedAmountOfVault,
    delegatedAmount,
    claimableRewardOfVault,
    claimableFlareDropOfVault,
    nextClaimableMonthOfVault,
    totalSupply,
    VISTokenOfAccount,
    pendingRewardOfVault,
    delegationsOfVault,
    VISTokenOfVault,
    wFLRTokenOfVault,
    claimableAmountOfAccountInVault,
    claimableAmount1OfAccountInVault,
    isWithdrawal,
    delegatable,
    worthsByIdInVault,
    meanWorthOfNFT,
    wNatBalanceOfAccount,
    delegate2TopDelegatees,
    withdrawShareOfVault,
    claimRewardOfVault,
    claimFlareDropOfVault
  }
}


export const useVote4Delegation = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [votable, setVotable] = useState(false);
  const [votedDelegations, setVotedDelegations] = useState<VotedDelegation[]>([]);
  const [isGettingDelegations, setIsGettingDelegations] = useState(false);
  const [votingAdd, setVotingAdd] = useState<string | undefined>(undefined);
  const [isLoadingVote, setIsLoadingVote] = useState(false);
  const [currentVotingAdd, setCurrentVotingAdd] = useState("");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 114) setVotingAdd(C2_Vote4Delegation_ADD);
  }, [chainId])

  const Vote = useMemo(() => {
    if (!library || !votingAdd) return;
    return Vote4Delegation__factory.connect(
      votingAdd,
      library.getSigner()
    )
  }, [library, votingAdd]);

  const votingToDelegatee = useCallback(async (delegatee: string) => {
    if (!account || !Vote) return;
    setIsLoadingVote(true);
    setCurrentVotingAdd(delegatee);
    try {
      const tx = await Vote.vote4Delegation(delegatee);
      await tx.wait();
      setIsLoadingVote(false);
      await getVotedDelegations();
      notifyInfo("You voted to " + delegatee + " successfully.")
    } catch (error) {
      console.log(error);
      setIsLoadingVote(false);
      notifyError("Voting Failed");
    }
  }, [Vote, account])

  const getVotedDelegations = useCallback(async () => {
    if (!Vote) return;

    setIsGettingDelegations(true);

    const res = await Vote.getDelegateeList();
    Promise.all(
      res.map((delegatee) =>
        Vote.getVoteOfDelegation(delegatee)
          .then((voted) => {
            return {
              delegatee: delegatee,
              voted: voted.toNumber()
            }
          })
      )
    ).then(res => {
      setVotedDelegations(res);
    }).finally(() => {
      setIsGettingDelegations(false);
    })
  }, [Vote])

  useEffect(() => {
    if (!Vote) return;
    getVotedDelegations();
  }, [Vote])

  return {
    votable,
    votedDelegations,
    isGettingDelegations,
    votingToDelegatee,
    isLoadingVote,
    currentVotingAdd
  }

}

export const useOracleVote4Delegation = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [votable, setVotable] = useState(false);
  const [votedDelegations, setVotedDelegations] = useState<VotedDelegation[]>([]);
  const [isGettingDelegations, setIsGettingDelegations] = useState(false);
  const [votingAdd, setVotingAdd] = useState<string | undefined>(undefined);
  const [isLoadingVote, setIsLoadingVote] = useState(false);
  const [currentVotingAdd, setCurrentVotingAdd] = useState("");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 19) setVotingAdd(SGB_Vote4Delegation_ADD);
  }, [chainId])

  const Vote = useMemo(() => {
    if (!library || !votingAdd) return;
    return ORACLEVote4Delegation__factory.connect(
      votingAdd,
      library.getSigner()
    )
  }, [library, votingAdd]);

  const votingToDelegatee = useCallback(async (delegatee: string) => {
    if (!account || !Vote) return;
    setIsLoadingVote(true);
    setCurrentVotingAdd(delegatee);
    try {
      const tx = await Vote.vote4Delegation(delegatee);
      await tx.wait();
      notifySuccess("You Voted To " + delegatee + " Successfully.")
      setIsLoadingVote(false);
      await getVotedDelegations();
    } catch (error) {
      setIsLoadingVote(false);
      notifyError("Voting Failed");
      console.log(error);
    }
  }, [Vote, account])

  const getVotedDelegations = useCallback(async () => {
    if (!Vote) return;

    setIsGettingDelegations(true);

    const res = await Vote.getDelegateeList();
    Promise.all(
      res.map((delegatee) =>
        Vote.getVoteOfDelegation(delegatee)
          .then((voted) => {
            return {
              delegatee: delegatee,
              voted: voted.toNumber()
            }
          })
      )
    ).then(res => {
      setVotedDelegations(res);
    }).finally(() => {
      setIsGettingDelegations(false);
    })
  }, [Vote])

  useEffect(() => {
    if (!Vote) return;
    getVotedDelegations();
  }, [Vote])

  return {
    votable,
    votedDelegations,
    isGettingDelegations,
    isLoadingVote,
    currentVotingAdd,
    votingToDelegatee
  }

}


export const useOracleVault = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [oracleVaultAdd, setOracleVaultAdd] = useState<string | undefined>(undefined);
  const [oracleERC20Token, setOracleERC20Token] = useState<string | undefined>(undefined);
  const [proERC20Token, setPROERC20Token] = useState<string | undefined>(undefined);

  const [oracleERC20OfVault, setOracleERC20OfVault] = useState<TokenInfo>(initTokenInfo);
  const [proERC20TokenOfVault, setProERC20TokenOfVault] = useState<TokenInfo>(initTokenInfo);
  const [wNatOfVault, setWNatOfVault] = useState<TokenInfo>(initTokenInfo);

  const [pendingRewardOfVault, setPendingRewardOfVault] = useState(0);
  const [delegationsOfVault, setDelegationsOfVault] = useState<IAmountOfDelegation[]>([]);

  const [lockedAmountOfVault, setLockedAmountOfVault] = useState(0);
  const [claimableRewardOfVault, setClaimableRewardOfVault] = useState(0);
  const [delegatedAmount, setDelegatedAmount] = useState(0);

  const [curWNatOfAcc, setCurWNatOfAcc] = useState(0);
  const [curOracleERC20OfAcc, setCurOracleERC20OfAcc] = useState(0);
  const [curProERC20OfAcc, setCurProERC20OfAcc] = useState(0);

  const [totalVolume, setTotalVolume] = useState(0);
  const [delegatable, setDelegatable] = useState(false);

  const [isWithdrawalOracle, setIsWithdrawalOracle] = useState(false);
  const [isWithdrawalPro, setIsWithdrawalPro] = useState(false);
  const [isWithdrawalWNat, setIsWithdrawalWNat] = useState(false);

  const wNatAddress = useContractAddress("WNat");
  const MulticallAddress = useContractAddress("MultiContract");

  const { rewardManager, rewardManagerAddress } = useRewardManager();


  const { epoch, vpBlock } = useCurrentRewardEpoch();

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 19) {
      setOracleVaultAdd(SGB_Vault_ADD);
      setOracleERC20Token(SGB_ORACLERC20);
      setPROERC20Token(SGB_PROERC20);
    }
  }, [chainId])

  const OracleVault = useMemo(() => {
    if (!library || !oracleVaultAdd) return;
    return Oracle_Vault__factory.connect(
      oracleVaultAdd,
      library.getSigner()
    )
  }, [library, oracleVaultAdd])

  const initialStatus = useCallback(async () => {
    if (!oracleVaultAdd || !MulticallAddress || !wNatAddress || !oracleERC20Token || !proERC20Token || !chainId || !account || epoch < 1 || vpBlock < 1 || !rewardManagerAddress) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: SongbirdProvider,
      tryAggregate: true
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_OracleVault',
        contractAddress: oracleVaultAdd,
        abi: OracleVaultJSON.abi,
        calls: [
          { reference: "res_getTokenInfo0", methodName: "getTokenInfo", methodParameters: [wNatAddress] },
          { reference: "res_getTokenInfo1", methodName: "getTokenInfo", methodParameters: [oracleERC20Token] },
          { reference: "res_getTokenInfo2", methodName: "getTokenInfo", methodParameters: [proERC20Token] },
          { reference: "res_getClaimableAmount0", methodName: "getClaimableAmount", methodParameters: [account, wNatAddress] },
          { reference: "res_getClaimableAmount1", methodName: "getClaimableAmount", methodParameters: [account, oracleERC20Token] },
          { reference: "res_getClaimableAmount2", methodName: "getClaimableAmount", methodParameters: [account, proERC20Token] }
        ]
      },
      {
        reference: 'res_vpToken',
        contractAddress: wNatAddress,
        abi: WNatJSON.abi,
        calls: [
          { reference: "res_delegatesOf", methodName: "delegatesOf", methodParameters: [oracleVaultAdd] },
          { reference: "res_balanceOfAt", methodName: "balanceOfAt", methodParameters: [oracleVaultAdd, vpBlock] },
          { reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [account] },
        ]
      },
      {
        reference: 'res_OracleERC20',
        contractAddress: oracleERC20Token,
        abi: ERC20JSON.abi,
        calls: [{ reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [account] }]
      },
      {
        reference: 'res_ProERC20',
        contractAddress: proERC20Token,
        abi: ERC20JSON.abi,
        calls: [{ reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [account] }]
      },
      {
        reference: 'res_rewardManager',
        contractAddress: rewardManagerAddress,
        abi: FtsoRewardManagerJSON.abi,
        calls: [{ reference: "res_getStateOfRewards", methodName: "getStateOfRewards", methodParameters: [oracleVaultAdd, epoch] },]
      },

    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_getTokenInfo0 = res.res_OracleVault.callsReturnContext[0].returnValues;
      const res_getTokenInfo1 = res.res_OracleVault.callsReturnContext[1].returnValues;
      const res_getTokenInfo2 = res.res_OracleVault.callsReturnContext[2].returnValues;

      const res_getClaimableAmount0 = res.res_OracleVault.callsReturnContext[3].returnValues[0];
      const res_getClaimableAmount1 = res.res_OracleVault.callsReturnContext[4].returnValues[0];
      const res_getClaimableAmount2 = res.res_OracleVault.callsReturnContext[5].returnValues[0];

      let getClaimableAmount0 = parseFloat(formatEther(res_getClaimableAmount0))
      let getClaimableAmount1 = parseFloat(formatEther(res_getClaimableAmount1))
      let getClaimableAmount2 = parseFloat(formatEther(res_getClaimableAmount2))


      const res_delegatesOf = res.res_vpToken.callsReturnContext[0].returnValues;
      const res_balanceOfAt = res.res_vpToken.callsReturnContext[1].returnValues[0];
      const res_balWNatOf = res.res_vpToken.callsReturnContext[2].returnValues[0];

      const res_balOracle20Of = res.res_OracleERC20.callsReturnContext[0].returnValues[0];
      const res_balPro20Of = res.res_OracleERC20.callsReturnContext[0].returnValues[0];

      const res_getStateOfRewards = res.res_rewardManager.callsReturnContext[0].returnValues;


      const temp0: TokenInfo = initTokenInfo;
      temp0.balance = parseFloat(formatEther(res_getTokenInfo0[0]));
      temp0.name = res_getTokenInfo0[1][0];
      temp0.claimedAmount = parseFloat(formatEther(res_getTokenInfo0[1][1]));
      temp0.remainPerNFT = parseFloat(formatEther(res_getTokenInfo0[1][2]));
      temp0.unlockTimestamp = Number(BigNumber.from(res_getTokenInfo0[1][3]));
      temp0.claimableAmount = getClaimableAmount0;
      setWNatOfVault({ ...temp0 });

      const temp1: TokenInfo = initTokenInfo;
      temp1.balance = parseFloat(formatEther(res_getTokenInfo1[0]));
      temp1.name = res_getTokenInfo1[1][0];
      temp1.claimedAmount = parseFloat(formatEther(res_getTokenInfo1[1][1]));
      temp1.remainPerNFT = parseFloat(formatEther(res_getTokenInfo1[1][2]));
      temp1.unlockTimestamp = Number(BigNumber.from(res_getTokenInfo1[1][3]));
      temp1.claimableAmount = getClaimableAmount1;
      setOracleERC20OfVault({ ...temp1 });

      const temp2: TokenInfo = initTokenInfo;
      temp2.balance = parseFloat(formatEther(res_getTokenInfo2[0]));
      temp2.name = res_getTokenInfo2[1][0];
      temp2.claimedAmount = parseFloat(formatEther(res_getTokenInfo2[1][1]));
      temp2.remainPerNFT = parseFloat(formatEther(res_getTokenInfo2[1][2]));
      temp2.unlockTimestamp = Number(BigNumber.from(res_getTokenInfo2[1][3]));
      temp2.claimableAmount = getClaimableAmount2;
      setProERC20TokenOfVault({ ...temp2 });

      let currenttimestamp = Math.floor(new Date().getTime() / 1000);

      if (Number(BigNumber.from(res_getTokenInfo0[1][3])) < currenttimestamp && getClaimableAmount0 > 0) setIsWithdrawalWNat(true)

      if (Number(BigNumber.from(res_getTokenInfo1[1][3])) < currenttimestamp && getClaimableAmount1 > 0) setIsWithdrawalOracle(true)

      if (Number(BigNumber.from(res_getTokenInfo2[1][3])) < currenttimestamp && getClaimableAmount2 > 0) setIsWithdrawalPro(true)

      let allocated = 0;
      for (let i = 0; i < res_delegatesOf[0].length; i++) {
        allocated += Number(BigNumber.from(res_delegatesOf[1][i])) / 10000;
      }
      setDelegatedAmount(allocated * parseFloat(formatEther(res_getTokenInfo0[0])));

      let totalAmount = 0;
      let tempArr = [];

      for (let i = 0; i < res_getStateOfRewards[0].length; i++) {
        let item = {
          address: res_getStateOfRewards[0][i],
          amount: Number(formatEther(res_getStateOfRewards[1][i]))
        }
        tempArr.push(item);
        totalAmount += item.amount;
      }
      setPendingRewardOfVault(totalAmount);
      setDelegationsOfVault(tempArr);
      setTotalVolume(parseFloat(formatEther(res_getTokenInfo0[1][1])) + parseFloat(formatEther(res_getTokenInfo0[0])));
      setLockedAmountOfVault(parseFloat(formatEther(res_balanceOfAt)));
      setCurWNatOfAcc(parseFloat(formatEther(res_balWNatOf)));
      setCurOracleERC20OfAcc(parseFloat(formatEther(res_balOracle20Of)));
      setCurProERC20OfAcc(parseFloat(formatEther(res_balPro20Of)));
    } catch (error) {
      console.log(error);
    }

  }, [oracleVaultAdd, MulticallAddress, wNatAddress, chainId, account, oracleERC20Token, proERC20Token, rewardManagerAddress, epoch, vpBlock])

  useEffect(() => {
    initialStatus();
  }, [oracleVaultAdd, MulticallAddress, wNatAddress, chainId, account, oracleERC20Token, proERC20Token, rewardManagerAddress, epoch, vpBlock])

  useEffect(() => {
    getUnclaimedReward();
  }, [rewardManager, oracleVaultAdd])

  useEffect(() => {
    getDelegatable();
  }, [OracleVault])

  const getDelegatable = useCallback(async () => {
    if (!OracleVault) return;
    OracleVault.delegatable().then(res => setDelegatable(res))
  }, [OracleVault])

  const getUnclaimedReward = useCallback(async () => {
    if (!rewardManager || !oracleVaultAdd) return;
    try {
      const unclaimedEpochs = await rewardManager.getEpochsWithUnclaimedRewards(oracleVaultAdd);
      let reward = 0;

      for (let i = 0; i < unclaimedEpochs.length; i++) {
        const epoch = unclaimedEpochs[i].toNumber();
        const rewardAmounts = await rewardManager.getStateOfRewards(oracleVaultAdd, epoch);

        let pieceOfReward = rewardAmounts._rewardAmounts.reduce((a, b) => {
          return a + Number(formatEther(b));
        }, 0);
        reward += pieceOfReward;
      }
      setClaimableRewardOfVault(reward);
    } catch (error) {
      console.error(error);
    }

  }, [rewardManager, oracleVaultAdd])

  const claimRewardOfVault = useCallback(async () => {
    if (!OracleVault) return;
    try {
      const tx = await OracleVault.claimReward();
      await tx.wait();
      setClaimableRewardOfVault(0)
    } catch (error) {
      console.log(error)
      notifyError("Claiming Reward of Vault Failed");
    }

  }, [OracleVault])

  const delegate2TopDelegatees = useCallback(async () => {
    if (!OracleVault || !delegatable) return;
    try {
      const tx = await OracleVault.delegate();
      await tx.wait();
      await getDelegatable();
      notifySuccess("Delegation Success");
    } catch (error) {
      notifyError("Delegation Failed");
    }
  }, [OracleVault, delegatable])

  const claimERC20OfVault = useCallback(async (token: string) => {
    if (!OracleVault || !wNatAddress) return;
    try {
      const tx = await OracleVault.withdrawERC20(token);
      await tx.wait();
      if (token === SGB_ORACLERC20) {
        setIsWithdrawalOracle(false);
        setCurOracleERC20OfAcc(curOracleERC20OfAcc + oracleERC20OfVault.claimableAmount);
        setOracleERC20OfVault({ ...oracleERC20OfVault, claimableAmount: 0 });
      }
      if (token === SGB_PROERC20) {
        setIsWithdrawalPro(false);
        setCurProERC20OfAcc(curProERC20OfAcc + proERC20TokenOfVault.claimableAmount);
        setProERC20TokenOfVault({ ...proERC20TokenOfVault, claimableAmount: 0 });
      }
      if (token === SGB_WNat) {
        setIsWithdrawalWNat(false);
        setCurWNatOfAcc(curWNatOfAcc + wNatOfVault.claimableAmount);
        setWNatOfVault({ ...wNatOfVault, claimableAmount: 0, });
      }
      notifySuccess("Withdrawal Success")
    } catch (error) {
      notifyWarn("Withdrawal failed")
    }
  }, [OracleVault, wNatAddress])

  return {
    wNatOfVault,
    oracleERC20OfVault,
    proERC20TokenOfVault,
    totalVolume,
    pendingRewardOfVault,
    claimableRewardOfVault,
    delegationsOfVault,
    delegatedAmount,
    delegatable,
    lockedAmountOfVault,
    curWNatOfAcc,
    curOracleERC20OfAcc,
    curProERC20OfAcc,
    isWithdrawalOracle,
    isWithdrawalPro,
    isWithdrawalWNat,
    claimRewardOfVault,
    claimERC20OfVault,
    delegate2TopDelegatees
  }
}


export const useOracle = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [oracleNFTAdd, setOracleNFTAdd] = useState<string | undefined>(undefined);
  const [maxSupply, setMaxSupply] = useState<number | undefined>(undefined);
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const [walletUris, setWalletUris] = useState<string[] | undefined>(undefined);
  const [baseUri, setBaseUri] = useState<string | undefined>(undefined);
  const [oracleIdsOfAccount, setOracleIdsOfAccount] = useState<number[] | undefined>(undefined);

  const MulticallAddress = useContractAddress("MultiContract");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 19) setOracleNFTAdd(SGB_ORACLE);
  }, [chainId])

  const initialStatus = useCallback(async () => {
    if (!oracleNFTAdd || !MulticallAddress || !chainId || !account) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: SongbirdProvider,
      tryAggregate: true
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_OracleNFT',
        contractAddress: oracleNFTAdd,
        abi: OracleNFTABI,
        calls: [
          { reference: "res_balance", methodName: "balanceOf", methodParameters: [account] },
          { reference: "res_maxSupply", methodName: "maxSupply", methodParameters: [] },
          { reference: "res_baseURI", methodName: "baseURI", methodParameters: [] },
          { reference: "res_walletOfOwner", methodName: "walletOfOwner", methodParameters: [account] },
        ]
      }
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_balance = res.res_OracleNFT.callsReturnContext[0].returnValues[0];
      const res_maxSupply = res.res_OracleNFT.callsReturnContext[1].returnValues[0];
      const res_baseURI = res.res_OracleNFT.callsReturnContext[2].returnValues[0];
      const res_walletOfOwner = res.res_OracleNFT.callsReturnContext[3].returnValues;

      setBalance(Number(BigNumber.from(res_balance)));
      setMaxSupply(Number(BigNumber.from(res_maxSupply)));
      setBaseUri(convertIpfs2CommonURI(res_baseURI));

      if (res_walletOfOwner.length > 0)
        setOracleIdsOfAccount(res_walletOfOwner.map((id: BigNumber) => Number(BigNumber.from(id))));
    } catch (error) {
      console.log(error);
    }

  }, [oracleNFTAdd, MulticallAddress, chainId, account])

  useEffect(() => {
    initialStatus();
  }, [oracleNFTAdd, MulticallAddress, chainId, account])

  useEffect(() => {
    if (!oracleIdsOfAccount || oracleIdsOfAccount.length < 1 || !baseUri) {
      setWalletUris(undefined);
      return;
    }
    getUris(oracleIdsOfAccount)
      .then((uris) => {
        setWalletUris(uris);
      }).catch((e) => {
        setWalletUris(undefined);
        notifyError("Cannot read images of your Arc NFTs");
      });
  }, [oracleIdsOfAccount, baseUri]);

  const getUris = useCallback(async (ids: number[]) => {
    if (!baseUri) return undefined;
    const uris = [];
    try {
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        if (id === 0) continue;
        const metadata = (await (await fetch(baseUri + id + ".json")).json())["image"];
        uris.push(convertIpfs2CommonURI(metadata));
      }
    } catch (error) {
      console.log(error)
      return undefined;
    }
    return uris;
  }, [baseUri]);

  return {
    oracleNFTAdd,
    balance,
    maxSupply,
    oracleIdsOfAccount,
    walletUris,
  }
}

export const useDistributeVision = () => {
  const { library, chainId } = useWeb3React<Web3Provider>();
  const [distributeVisionAdd, setDistributeVisionAdd] = useState<string | undefined>(undefined);
  const [distTimeInterval, setDistTimeInterval] = useState(0);
  const [distNextTimestamp, setDistNextTimestamp] = useState(0);
  const [distRemnants, setDistRemnants] = useState(0);
  const [distLeft, setDistLeft] = useState(0);

  const MulticallAddress = useContractAddress("MultiContract");

  useEffect(() => {
    if (!chainId) return;
    if (chainId === 114) setDistributeVisionAdd(C2_VistionDist_ADD);
  }, [chainId])

  const DistVision = useMemo(() => {
    if (!library || !distributeVisionAdd) return;
    return DistributeVision__factory.connect(
      distributeVisionAdd,
      library.getSigner()
    )
  }, [library, distributeVisionAdd])

  const initialStatus = useCallback(async () => {
    if (!distributeVisionAdd || !MulticallAddress) return;
    const multicall = new Multicall({
      multicallCustomContractAddress: MulticallAddress,
      ethersProvider: Coston2Provider,
      tryAggregate: true
    });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'res_DistVision',
        contractAddress: distributeVisionAdd,
        abi: DistributeVisionABI,
        calls: [
          { reference: "res_nexttimestamp", methodName: "nextDistribution", methodParameters: [] },
          { reference: "res_timeinterval", methodName: "timeInterval", methodParameters: [] },
          { reference: "res_distAmount", methodName: "distAmount", methodParameters: [] },
        ]
      },
      {
        reference: 'res_VISERC20',
        contractAddress: C2_VISTOKEN_ADD,
        abi: ERC20JSON.abi,
        calls: [{ reference: "res_balanceOf", methodName: "balanceOf", methodParameters: [distributeVisionAdd] }]
      },
    ]

    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const res = results.results;
      const res_nexttimestamp = res.res_DistVision.callsReturnContext[0].returnValues[0];
      const res_timeinterval = res.res_DistVision.callsReturnContext[1].returnValues[0];
      const res_distAmount = res.res_DistVision.callsReturnContext[2].returnValues[0];
      const res_balanceOf = res.res_VISERC20.callsReturnContext[0].returnValues[0];
      setDistTimeInterval(Number(BigNumber.from(res_timeinterval)));
      setDistNextTimestamp(Number(BigNumber.from(res_nexttimestamp)));
      setDistRemnants(parseFloat(formatEther(res_distAmount)));
      setDistLeft(parseFloat(formatEther(res_balanceOf)));
    } catch (error) {
      console.log(error);
    }

  }, [distributeVisionAdd, MulticallAddress])

  useEffect(() => {
    initialStatus();
  }, [distributeVisionAdd, MulticallAddress])

  const resetTimeStamp = useCallback(() => {
    if (distTimeInterval < 1) return;
    setDistNextTimestamp(distNextTimestamp + distTimeInterval);
  }, [distTimeInterval])

  const bestowVision = useCallback(async () => {
    if (!DistVision || distLeft===0 || distRemnants===0) return;
    try {
      const nextTime = await DistVision.nextDistribution();
      const timeInterval = await DistVision.timeInterval();
      if (nextTime.toNumber() <= Math.floor(new Date().getTime() / 1000)) {
        const tx = await DistVision.distributeVision();
        await tx.wait();
        setDistNextTimestamp(nextTime.toNumber() + timeInterval.toNumber());
        setDistLeft(distLeft-distRemnants)
        notifySuccess("Vision Distribution to Vault Success");
      } else {
        setDistNextTimestamp(nextTime.toNumber());
      }
    } catch (error) {
      console.log(error)
      notifyError("Vision Distribution to Vault Failed");
    }

  }, [DistVision, distLeft, distRemnants])

  return {
    distTimeInterval,
    distNextTimestamp,
    distRemnants,
    distLeft,
    bestowVision,
    resetTimeStamp,
  }
}


