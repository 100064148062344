import songbirdSvg from "../assets/songbird.svg";
import flareSvg from "../assets/FLR.svg";
import wrapsongbirdSvg from "../assets/wrapsongbird.svg";
import wrapflareSvg from "../assets/WFLR.svg";

export const SGB = {
  status: {
    amount: "",
    balance: "0.0000",
    price: "1.0000",
  },
  display: {
    isSvg: true,
    picInfo: {
      id: "Songbird",
      className: "fill-current text-pink-800 h-5 w-5 ml-2 mr-[25px]",
      alt: "sgb",
      color: "",
      title: "sgb",
      url: songbirdSvg,
    },
  },
};
export const FLR = {
  status: {
    amount: "",
    balance: "0.0000",
    price: "1.0000",
  },
  display: {
    isSvg: true,
    picInfo: {
      id: "Flare",
      className: "fill-current text-pink-800 h-5 w-5 ml-2 mr-[25px]",
      alt: "flr",
      color: "",
      title: "flr",
      url: flareSvg,
    },
  },
};
export const WSGB = {
  status: {
    amount: "",
    balance: "0.000",
    price: "1.000",
  },
  display: {
    isSvg: true,
    picInfo: {
      id: "Wrapped Songbird",
      className: "fill-current text-gray-800 h-5 w-5 mx-2",
      alt: "wsgb",
      color: "",
      title: "wsgb",
      url: wrapsongbirdSvg,
    },
  },
};
export const WFLR = {
  status: {
    amount: "",
    balance: "0.000",
    price: "1.000",
  },
  display: {
    isSvg: true,
    picInfo: {
      id: "Wrapped Flare",
      className: "fill-current text-gray-800 h-5 w-5 mx-2",
      alt: "wflr",
      color: "",
      title: "wflr",
      url: wrapflareSvg,
    },
  },
};
export const UnknownProvider = {
  "chainId": 19,
  "name": "Unknown Provider",
  "description": "This is an unknown provider, but you can delegate to this provider",
  "url": "https://www.oracleswap.io",
  "address": "0x4E6c61d1DC945EeBd3Fc9A6251BD8B8b5EbD5637",
  "logoURI": "https://avatars.dicebear.com/api/pixel-art-neutral/0x4E6c61d1DC945EeBd3Fc9A6251BD8B8b5EbD5637.png",
  "listed": true,
}
